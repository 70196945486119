import { ISelectorElements } from '@/interfaces/selector-elements';
import store from '@/store';
import Helpers from '@/utils/helpers';


export interface InstitutionItem {
    id: number;
    institutionId: number;
    graduate: number;
    graduateDate: Date | string;
    graduation: number;
    courseId: number;
    file: string;
    fileType: string;
    fileName: string;
}

export interface IRequestProfessionalData {
    uuid: string;
    hasEConcil: boolean;
    specialtys: number[];
    mostChallengingSituation: string;
    institutionList: InstitutionItem[];
    convenienceServiceAnyWhereTime: boolean;
}


export const transform = (data: any | undefined): IRequestProfessionalData => {
  if (!data) throw new Error("Can't transform null value");
  data.specialtys = data.specialtys.map((x: string) => {
    const reg = store.state.specialtys.filter((y: any) => y.nome === x);
    if(reg?.length > 0) return reg[0].id;
    }
  );
  const response = {
    uuid: data.uuid,
    hasEConcil: data.hasEConcil,
    specialtys: data.specialtys,
    mostChallengingSituation: data.mostChallengingSituation,
    institutionList: data.institutionsList.map((institution: ISelectorElements) => {
      institution.data.graduateDate = Helpers.toJsonDate(institution.data.graduateDate);
      return institution.data;
    })
  } as IRequestProfessionalData;
  return response;
};