import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    form: _ctx.form,
    fields: _ctx.filterFieldsByProfession,
    title: `Cadastro de ${_ctx.profession.label}`,
    formRules: _ctx.formRules,
    loading: _ctx.loading,
    targetRendering: _ctx.profession.target,
    onChange: _ctx.handleFieldChange,
    onSubmit: _ctx.submitForm
  }, null, 8, ["form", "fields", "title", "formRules", "loading", "targetRendering", "onChange", "onSubmit"]))
}