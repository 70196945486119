
import {
    defineComponent,
} from 'vue';
import CustomField from '@/components/orienteme/CustomField.vue';
import {IFieldEvent} from '@/interfaces/field';
import { ElNotification } from 'element-plus';

export default defineComponent({
    name: 'custom-field',
    emits: ['change', 'submit', 'searchZipCode'],
    components: {
        CustomField
    },
    props: {
        title: {
            type: String,
            required: true
        },
        fields: {
            type: Object,
            required: true
        },
        form: {
            required: true
        } as any,
        formRules: {} as any,
        loading: {
            type: Boolean
        } as any,
        targetRendering: {
            type: String,
            required: true
        }
    },
    methods: {
        handleFieldChange(event: IFieldEvent): void {
            this.$emit('change', event);
        },
        handleFormSubmission(): void {
            const refs = this.$refs['cform'] as Record < string,any > ;
            refs.validate((valid: boolean) => {
                if (valid) {
                    this.$emit('submit', 'form');
                }else{
                    ElNotification({
                        title: 'Aviso',
                        message: 'Favor preencher todos os campos',
                        duration: 3000,
                        type: 'warning'
                    });
                }
            });
        },
        handleSearchZipCode(event: IFieldEvent): void {
            this.$emit('searchZipCode', event);
        }
    },
    computed: {}
});
