import {IField} from '../../interfaces/field';
import {STATES, PROFESSIONS} from '../../constants';
const allProfessions = [
    PROFESSIONS.psicologos.target,
    PROFESSIONS.nutricionistas.target,
    PROFESSIONS.orientacaofisica.target
];
export const BASIC_FORM = [
    {
        title: 'Nome Completo',
        subtitle: 'Exemplo. Maria da Silva',
        prop: 'name',
        targets: allProfessions,
        type: 'input',
    } as IField,
    {
        title: 'E-mail',
        subtitle: 'Exemplo maria@gmail.com',
        prop: 'email',
        targets: allProfessions,
        col: 12,
        type: 'input',
    } as IField,
    {
        title: 'Telefone',
        subtitle: 'Lembre-se de indicar o DDD  11 987654321',
        prop: 'phoneNumber',
        mask: ['(##) ####-####', '(##) #####-####'],
        maxlength: 15,
        targets: allProfessions,
        col: 12,
        type: 'input',
    },
    {
        title: 'CPF/CNPJ',
        subtitle: 'Use apenas números',
        prop: 'cpfcnpj',
        mask: ['###.###.###-##', '##.###.###/####-##'],
        maxlength: 18,
        targets: allProfessions,
        col: 8,
        type: 'input',
    },
    {
        title: 'Número do CRN',
        subtitle: 'Use apenas números',
        prop: 'professionalCouncilNumber',
        targets: [PROFESSIONS.nutricionistas.target],
        col: 8,
        type: 'input',
    } as IField,
    {
        title: 'Número do CRP',
        subtitle: 'Use apenas números',
        prop: 'professionalCouncilNumber',
        targets: [PROFESSIONS.psicologos.target],
        col: 8,
        type: 'input',
    } as IField,
    {
        title: 'Número do CREF',
        subtitle: 'Use apenas números',
        prop: 'professionalCouncilNumber',
        targets: [PROFESSIONS.orientacaofisica.target],
        col: 8,
        type: 'input',
    } as IField,
    {
        title: 'UF',
        subtitle: 'Selecione um estado',
        prop: 'professionalCouncilState',
        targets: allProfessions,
        maxlength: 2,
        col: 6,
        type: 'select',
        options: STATES
    } as IField,
];