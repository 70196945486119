
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import store from '@/store';
import UploadButton from '@/components/orienteme/UploadButton.vue';
import CustomField from '@/components/orienteme/CustomField.vue';
import SelectorElements from '@/components/orienteme/SelectorElements.vue';
import Rules from '@/utils/rules';
import signup from '@/mixins/signup';
import UploadImage from '@/mixins/upload-image';
import notification from '@/mixins/notification';
import { PROFESSIONAL_FORM, STEPS } from '@/constants';
import { InstitutionItem, transform } from '@/constants/contracts/professionalData';
import { ISelectorElements } from '@/interfaces/selector-elements';
import Helpers from '@/utils/helpers';
export const TAG_PSICHOLOGY = 'psicologos';


export default defineComponent({
	components: {  UploadButton, CustomField, SelectorElements },
	mixins: [signup, notification, UploadImage],
	store,
	data() {
		return {
			PROFESSIONAL_FORM,
			loading: false,
      lockState: false,
			valid: false,
			form: {
				institutionId: null,
				graduation: null,
				graduateDate: null,
				hasEConcil: null,
				mostChallengingSituation: '',
				specialtys: [],
				courseId: null,
				graduate: null,
				uuid: this.$route.query.uuid,
				institutionsList: [],
				pictureGraduation: [],
        pictureCouncil: [],
			} as any,
		};
	},
	computed: {
		formRules() {
			return {
				challenge: [Rules.required()],
				occupationArea: [Rules.required()],
        pictureCouncil: [Rules.required()]
			};
		},
		formGraduationRules(): any{
			return {
				courseId: [Rules.required()],
				institutionId: [Rules.required()],
				graduate: [Rules.required()],
				graduateDate: [Rules.required(), Rules.date()],
				graduation: [Rules.required()],
				pictureGraduation: [Rules.required()],
			};
		},
		filterGraduationForm(): any {
			return PROFESSIONAL_FORM.graduationForm.filter((el: any) => {
					const targets = el.targets as string[];
					return targets.includes(this.profession.target);
			});
		},
		filterOccupationForm() {
			return PROFESSIONAL_FORM.occupationForm.filter((el: any) => {
					const targets = el.targets as string[];
					return targets.includes(this.profession.target);
			});
		}
	},
	methods: {
		...mapActions(['fetchInstituitions',
		'fetchCourseList',
		'fetchGraduateList',
		'fetchGraduationList',
		'fetchProfessionalData',
		'fetchSpecialtyList',
    'fetchPersonalData',
		'postProfessionalData',
		'sendGraduationPicture',
		'sendCouncilDocument',
		'graduateListSelectOptions',
    'postPersonalData',
    'advanceStep']),

		async handleSubmitForm(formName: string): Promise<void> {
      if(this.lockState) return;

			const refFormGraduation = this.$refs['formGraduation'] as Record<string, any>;

      this.lockState = true;
      this.loading = true;


			refFormGraduation.validate(async (valid: boolean) => {
				const refs = this.$refs[formName] as Record<string, any>;
				this.valid = valid || this.form.institutionsList.length > 0;
				if (!this.valid) {
					this.warn('Favor preencher todos os campos obrigatórios');
					return;
				}
				if(valid){
          const newGraduation = await (this.buildGraduation(this.form)).fromForm();
          this.form.institutionsList.push(newGraduation);
          this.clearForm();
          this.lockState = true;
          this.loading = true;
				}
				refs.validate(async (valid: boolean) => {
					if (!valid) {
						this.warn('Favor preencher todos os campos obrigatórios');
						return;
					}
					try{
            if(this.form.hasEConcil == null){
              this.form.hasEConcil = false;
            }
            let sendPicture = !this.form.pictureCouncil[0].sync ? this.sendCouncilDocument({
								picture: this.form.pictureCouncil,
								uuid: this.form.uuid
							}) : null;
						await Promise.all([
							this.postProfessionalData(transform(this.form)),
              this.postPersonalData({
                ...store.state.user.personalData,
                convenienceServiceAnyWhereTime: this.form.convenienceServiceAnyWhereTime
              }),
							sendPicture,
						]);
						this.notifySuccess('Dados profissionais salvo com sucesso!');
            this.redirectStep(STEPS.Experiência);
					}
					catch(err: any){
						this.notify(err);
					}
          finally{
            this.lockState = false;
            this.loading = false;
          }
				});
			});
		},
		handleFieldChange({e, field} : any): void {
			if(field.type === 'upload'){
				this.form[field.prop] = [e];
			}else{
				this.form[field.prop] = e;
			}
		},
		async handleClickAddGraduation(): Promise<void>{
			const refs = this.$refs['formGraduation'] as Record<string, any>;

			refs.validate(async (valid: boolean) => {
				if (!valid) {
					this.warn('Favor preencher todos os campos obrigatórios');
					return;
				}
				const newGraduation = await (this.buildGraduation(this.form)).fromForm();
        this.form.institutionsList.push(newGraduation);
        this.clearForm();
			});
		},
		handleClickRemoveGraduation({item}: any): void {
			for(let i = 0; i < this.form.institutionsList.length; i++){
				const grad = this.form.institutionsList[i];
				if(item.id === grad.id)
					this.form.institutionsList.splice(i, 1);
			}
		},
    clearForm(){
      this.form.graduate = null;
      this.form.graduation = null;
      this.form.institutionId = null;
      this.form.graduateDate = null;
      this.form.courseId = null;
      this.form.pictureGraduation = [];
    },
    buildGraduation(rawData: any): any {
      const header = store.state.graduateList.filter((x:any) => x.id == rawData.graduate)[0].nome;
      const graduation = store.state.graduationList.filter((x:any) => x.id == rawData.graduation)[0]?.nome;
      const institution = store.state.institutionsList.filter((x:any) => x.id == rawData.institutionId)[0]?.nome;
      return {
        fromForm: async() => {
          const picture = await Helpers.getBase64(rawData.pictureGraduation[0].raw);
          return {
            id: rawData.institutionsList.length ?? 0,
            header,
            title: `${graduation} - ${rawData.graduateDate}`,
            subtitle: institution,
            data: {
              institutionId: rawData.institutionId,
              graduateDate: rawData.graduateDate,
              graduate: rawData.graduate,
              graduation: rawData.graduation,
              courseId: rawData.courseId,
              file: picture,
              fileType: rawData.pictureGraduation[0].raw.type,
              fileName: rawData.pictureGraduation[0].name
            } as InstitutionItem
          } as ISelectorElements;
        },
        fromAPI: () =>{
          return{
            id: `${rawData.institutionId}${rawData.graduate}${rawData.graduation}${rawData.courseId}`,
            header,
            title: `${graduation} - ${Helpers.toDate(rawData.graduateDate)}`,
            subtitle: institution,
            data: {
              institutionId: rawData.institutionId,
              graduateDate: Helpers.toDate(rawData.graduateDate),
              graduate: rawData.graduate,
              graduation: rawData.graduation,
              courseId: rawData.courseId,
              file: rawData.file,
              fileType: rawData.fileType,
              fileName: rawData.fileName,
            } as InstitutionItem
          } as ISelectorElements;
        }
      };
    },
    builder(rawData: any, state: any, buildGraduation: any): any{
      const form = rawData;
      return {
        build(){
          return form;
        },
        graduateDate(){
          form.graduateDate = Helpers.toDate(rawData.graduateDate);
          return this;
        },
        specialtys(){
          try{
            let specialtys = rawData.specialtys;
            if(specialtys){
              specialtys = specialtys.map((x: number) => {
                const reg = state.specialtys.filter((y: any) => y.id === x);
                if(reg?.length > 0) return reg[0].nome;
              });
            }
            form.specialtys = specialtys;
          }
          catch(exc){
            console.log(exc);
          }
          return this;
        },
        institutionsList(){
          let institutionsList = [];
          for(let i = 0; i < rawData.institutions.length; i++){
            institutionsList.push(buildGraduation(rawData.institutions[i]).fromAPI());
          }
          form.institutionsList = institutionsList;
          return this;
        },
        pictureCouncil(){
          form.pictureCouncil = [{
            name: rawData.councilDocumentName,
            uid: rawData.councilDocumentUuid,
            sync: true,
            raw: {
              type: rawData.councilDocumentType,
              uid: rawData.councilDocumentUuid
            }
          }];
          return this;
        }
      };
    }
  },
	async mounted(){
		const professionType = this.profession.value as number;
    const uuid = this.$route.query.uuid;

    try {
      await Promise.all([
        this.fetchInstituitions(professionType),
        this.fetchGraduationList(professionType),
        this.fetchCourseList(professionType),
        this.fetchGraduateList(professionType),
        this.fetchSpecialtyList(professionType),
        this.fetchProfessionalData(uuid),
        this.fetchPersonalData(uuid)
      ]);
      this.form = this.builder({
        ...store.state.user.professionalData,
        ...store.state.user.personalData
        }, store.state,
        this.buildGraduation)
        .specialtys()
        .institutionsList()
        .graduateDate()
        .pictureCouncil()
        .build();
      this.clearForm();
      
    } catch (error: any) {
      if(error.statusCode !== 'NotFound'){
        throw error;
      } 
    }
	}
});
