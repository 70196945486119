
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['upload', 'remove'],
  props: {
    limit: {
      type: Number,
      required: true
    },
    model: {
      required: true
    }
  },
  computed: {
    hasFile({model}: any){
      return model?.length > 0;
    }
  },
  methods: {
    handleChange: function(file: any) {
      this.$emit('upload', file);
    },
    handleRemove: function(){
        this.$emit('remove');
    }
  }
});
