import { defineComponent } from 'vue';
import { ElNotification } from 'element-plus';

export default defineComponent({
  name: 'notification',
  methods: {
    notifySuccess: function (message: string) {
      ElNotification({
        title: 'Sucesso',
        message: message,
        duration: 4500,
        type: 'success'
      });
    },
    notify: function (err: any) {
      console.log(err);
      if (err.message === '[object Object]') {
        err.message = 'Erro inesperado, favor tentar novamente!';
      }
      if (err.erros?.length > 0) {
        for (let i = 0; i < err.erros.length; i++) {
          setTimeout(() => {
            ElNotification({
              title: 'Erro',
              message: err.erros[i],
              duration: 0,
              type: 'error'
            });
          }, 300);
        }
      } else {
        ElNotification({
          title: 'Erro',
          message: err.message,
          duration: 0,
          type: 'error'
        });
      }

    },
    notifyError: function (err: string, duration: number) {
      console.log(err);
      ElNotification({
        title: 'Erro',
        message: err,
        duration: duration,
        type: 'error'
      });
    },
    warn: function (err: string) {
      ElNotification({
        title: 'Aviso',
        message: err,
        duration: 3000,
        type: 'warning'
      });
    },
  }
});