import Helpers from '@/utils/helpers';
import { LANGUAGE, ONLINE_SERVICES } from '@/constants';
import { IOption } from '@/interfaces/field';
export interface PersonalDataPayload {
    uuid: string;
    name: string;
    cpfCnpj: string;
    dateOfBirth: string;
    gender: string;
    phoneNumber: string;
    professionalCouncilNumber: string;
    professionalCouncilState: string;
    referencePersonName: string;
    referencePersonPhoneNumber: string;
    referencePersonEmail: string;
    profession: number;
    language: string;
    additionalVolumeUsers: boolean;
    additionalIncome: boolean;
    convenienceServiceAnyWhereTime: boolean;
    easeReceivingPeriodicUsersReports: boolean;
    securityAutomatedPayment: boolean;
    securityHavingEncryptedConversations: boolean;
    others: boolean;
    hasCriminalRecords: boolean;
    howDidYouKnowPlataform: string;
    howIsYourInternetConnection: string;
    whatOperationSystemDoYouUse: string;
    similarityDifferenceOnLineService: string;
    whyDoYouWantToMeetOnThePlatform: string;
    internetSkillLevel: string;
}
export const transformToPersonalData = function(data: any | undefined): PersonalDataPayload | null{
    if(!data) return null;
    
    if(data.onlineServices){
        ONLINE_SERVICES.map((x:IOption) => data[x.key] = false);
        for(const onlineService of data.onlineService) {
            data[ONLINE_SERVICES.filter((x:any) => x.value == onlineService)[0].key] = true;
        }
        delete data.onlineService;
    }
    delete data.personalData;
    delete data.initialData;
    const response = {
        ...data,
        dateOfBirth: Helpers.toJsonDate(data.dateOfBirth),
        referencePersonPhoneNumber: Helpers.onlyNumbers(data.referencePersonPhoneNumber),
        internetSkillLevel: Helpers.onlyNumbers(data.internetSkillLevel),
        hasCriminalRecords: Boolean(data.hasCriminalRecords),
        phoneNumber: Helpers.onlyNumbers(data.phoneNumber),
        convenienceServiceAnyWhereTime: Boolean(data.convenienceServiceAnyWhereTime),

        //hidden parameters
        language: LANGUAGE.PORTUGUESE.toString()
    } as PersonalDataPayload;
    return response;
};