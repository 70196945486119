
import { defineComponent } from 'vue';
import { IStep } from '@/constants';
import { mapActions, mapGetters } from 'vuex';
import { Check } from '@element-plus/icons-vue';

export default defineComponent({
    componentes: { Check },
    computed: {
        ...mapGetters(['currentStep', 'registerSteps']),
    },
    methods: {
        ...mapActions(['goToStep']),
        redirect: function(step: IStep) {
          if(step.finished || step.inProgress){
            this.$router.replace({ name: step.name, query: this.$route.query });
            this.goToStep(step);
          }
        }
    }
});
