
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import ArrowLeft from '@/assets/svg/icons/arrow-left.svg';
import Steps from '@/components/orienteme/Steps.vue';
import signup from '@/mixins/signup';

export default defineComponent({
  components: { Steps },
  mixins: [signup],
  computed: {
    ...mapGetters(['isDebug', 'currentStep', 'stepVisible']),
    arrowLeft(): string {
      return ArrowLeft;
    },
    wesiteUrl(): string {
      return process.env.VUE_APP_WEBSITE_URL as string;
    },
    version(): string {
      return `Versão: ${process.env.VUE_APP_VERSION}`;
    },
    currentYear(): number {
      return new Date().getFullYear();
    },
  },
  methods: {
    ...mapActions(['restore']),
    goTo(url: string): void {
      window.location.href = url;
    },
  },
  async mounted() {
    await this.restore({ uuid: this.$route.query.uuid });
    this.$router.replace({ name: this.currentStep.name, query: this.$route.query });
  },
});
