import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { style: {"margin-top":"50px"} }
const _hoisted_2 = { style: {"text-align":"right"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_CustomField = _resolveComponent("CustomField")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    class: "pr-registration__content custom-form",
    ref: "cform",
    model: _ctx.form,
    rules: _ctx.formRules
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "header", {}, () => [
            _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1)
          ])
        ]),
        _: 3
      }),
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field, idx) => {
            return (_openBlock(), _createBlock(_component_CustomField, {
              key: idx,
              field: field,
              fieldValue: _ctx.form[field.prop],
              targetRendering: _ctx.targetRendering,
              onChange: _ctx.handleFieldChange,
              onSearchZipCode: _ctx.handleSearchZipCode
            }, null, 8, ["field", "fieldValue", "targetRendering", "onChange", "onSearchZipCode"]))
          }), 128))
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_divider)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_button, {
          type: "primary",
          loading: _ctx.loading,
          onClick: _cache[0] || (_cache[0] = (e) => _ctx.handleFormSubmission(e))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" CONFIRMAR ")
          ]),
          _: 1
        }, 8, ["loading"])
      ])
    ]),
    _: 3
  }, 8, ["model", "rules"]))
}