
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import store from '@/store';
import Form from '@/components/orienteme/CustomForm.vue';
import Rules from '@/utils/rules';
import signup from '@/mixins/signup';
import Notification from '@/mixins/notification';
import {IFieldEvent} from '@/interfaces/field';
import {EXPERIENCE_FORM, STEPS} from '@/constants';
import answers from '@/mixins/answers';

export default defineComponent({
  components: {  Form },
  store,
  mixins: [signup, Notification, answers],
  data() {
    return {
      EXPERIENCE_FORM,
      loading: false,
      lockState: false,
      form: {
        role: [],
        years: [],
        online: null,
        themes: [],
        patientsWeekly: null,
        patientsAge: null,
        haveSupervisor: null,
        youAreSupervisor: null,
      } as any,
    };
  },
  computed: {
    ...mapGetters(['answersListSelectOptions', 'answersByKey']),
    formRules() {
      return {
        role: [Rules.required()],
        years: [Rules.required()],
        online: [Rules.required()],
        themes: [Rules.required(), Rules.minimum(6)],
        patientsWeekly: [Rules.required()],
        patientsAge: [Rules.required()],
        challenge: [Rules.required()],
        haveSupervisor: [Rules.required()],
        youAreSupervisor: [Rules.required()],
      };
    },
    filterFieldsByProfession() {
      return EXPERIENCE_FORM.filter((el) => {
        const targets = el.targets as string[];
        return targets.includes(this.profession.target);
      });
    }
  },
  methods: {
    ...mapActions(['fetchAnswers', 'postExperience', 'fetchUserAnswers', 'refreshStateApp',
    'fetchGlobalAnswers']),
    async handleSubmitForm(): Promise<void> {
      if(this.lockState) return;

      const uuid = this.$route.query.uuid as string;
      this.lockState = true;
      this.loading = true;
      const answers = this.buildRequest(
        uuid,
        EXPERIENCE_FORM,
        this.profession.target,
        this.$data.form,
        'experienceAnswers'
      );
      try{
        await this.postExperience(answers);
        this.notifySuccess('Experiência salva com sucesso');
        this.redirectStep(STEPS.Disponibilidade);
      }
      catch(err: any){
        this.notify(err);
      }
      finally{
        this.lockState = false;
        this.loading = false;
      }
    },
    handleFieldChange(event: IFieldEvent): void{
      this.form[event.field.prop] = event.e;
    }
  },
  async mounted() {
    this.loading = true;
    this.refreshStateApp();
    try{
      const professionType = this.profession.value;
      await this.fetchGlobalAnswers({arrayAnswers: store.state.experienceAnswers,
        professionType,
        uuid: this.$route.query.uuid,
        storeQuestions: 'experienceAnswers',
        storeUserAnswers: 'experience'});
    }
    catch(err: any){
      this.notify(err);
    }
    finally{
      this.loading = false;
      this.renderUserAnswers(store.state.user.experience,
        EXPERIENCE_FORM,
        this.form,
        'experienceAnswers');
    }
  },

});
