import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "container-grad" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "course" }
const _hoisted_4 = { class: "institution" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { justify: "end" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 6,
          xs: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              id: "btn-add-graudation",
              onClick: _ctx.handleClickAddGraduation,
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" ADICIONAR ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_TransitionGroup, {
      tag: "div",
      name: "fade",
      class: "container main-row el-row"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (grad, i) => {
          return (_openBlock(), _createBlock(_component_el_col, {
            span: 12,
            xs: 24,
            key: i
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_el_row, { class: "header" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 12,
                      class: "titulation"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(grad.header), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_col, {
                      span: 12,
                      class: "titulation"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_row, { justify: "end" }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", {
                              class: "btn-excluir",
                              role: "button",
                              onClick: () => { _ctx.handleClickRemove(grad) }
                            }, " Excluir ", 8, _hoisted_2)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createElementVNode("b", _hoisted_3, _toDisplayString(grad.title), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(grad.subtitle), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}