import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {PROFESSIONS, IProfessions, IProfession, STEPS, IStep} from '@/constants';
import docTypes from '@/utils/docTypes';

export default defineComponent({
  created() {
    if (this.profession == null) {
      this.$router.replace('/');
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  data() {
    return {
      activeStep: 0,
      steps: STEPS
    };
  },
  computed: {
    ...mapGetters(['currentStep', 'isDebug']),
    profession(): IProfession {
      const professionIndex = this.$route.params.profession as keyof(IProfessions);
      return PROFESSIONS[professionIndex];
    },
    docType(): string {
      return docTypes[this.$route.params.profession as string];
    },
  },
  watch: {
    currentStep: {
      immediate: true,
      async handler (value: number) {
      this.setStepsVisible(true);
        if (value == null) {
          this.SET_STEP(STEPS['Dados Pessoais']);
          this.$router.replace({ name: 'signup.personaldata', query: this.$route.query });
        }
      }
    }
  },
  methods: {
    ...mapActions(['advanceStep', 'setStepsVisible']),
    ...mapMutations(['SET_STEP']),
    redirectStep(step: IStep){
      this.$route.meta.step = step;
      this.advanceStep(step);
      this.$router.replace({ name: step.name, query: this.$route.query});
    }
  }
});
