export const PROFESSION_QUESTIONS = {
    ATUALMENTE_VOCE_E_PACIENTE_DE_TERAPIA_ANALISE: 1,
    COLOQUE_NA_ESCALA_O_NUMERO_QUE_VOCE_CONSIDERA_ADEQUADO_AO_SEU_CONHECIMENTO_DE_INTERNET: 2,
    COM_QUE_FREQUENCIA_VOCE_CHECA_SEU_E_MAIL: 3,
    COMO_VOCE_DESCOBRIU_A: 4,
    COMO_VOCE_DESCOBRIU_A_ORIENTEME: 5,
    EM_MEDIA_QUANTAS_HORAS_VOCE_ATENDE_POR_SEMANA: 6,
    EM_MEDIA_QUANTOS_PACIENTES_POR_SEMANA_VOCE_ATENDE: 7,
    INDIQUE_A_FAIXA_ETARIA_MEDIA_DOS_SEUS_PACIENTES_ATUAIS: 8,
    INDIQUE_O_CONTATO_DE_PELO_MENOS_UM_TERAPEUTA_QUE_POSSA_SERVIR_DE_REFERENCIA: 9,
    JA_ATENDEU_ONLINE_SE_SIM_POR_QUE_MEIOS: 10,
    MINHA_CONEXAO_COM_A_INTERNET_E: 11,
    POR_QUE_O_ATENDIMENTO_ONLINE_TE_INTERESSA: 12,
    POR_QUE_VOCE_QUER_ATENDER_PELA_PLATAFORMA: 13,
    POSSUI_ANTECEDENTES_CRIMINAIS: 14,
    QUAL_E_A_SUA_ABORDAGEM_TERAPEUTICA_INDIQUE_TODAS_AS_APLICAVEIS: 15,
    QUAL_IS__FAIXA_S__ETARIA_S__VOCE_TEM_INTERESSE_EM_ATENDER_ONLINE: 16,
    QUANTO_TEMPO_VOCE_PODERA_DISPOR_PARA_TRABALHAR_COM_A_PLATAFORMA_DIARIAMENTE: 17,
    QUANTOS_ANOS_DE_EXPERIENCIA_COM_ATENDIMENTO_CLINICO_VOCE_TEM: 18,
    QUE_NAVEGADOR_VOCE_USA: 19,
    QUE_SISTEMA_OPERACIONAL_DE_SMARTPHONE_VOCE_USA: 20,
    SELECIONE_ATE_10_TEMAS_PRINCIPAIS_EM_QUE_VOCE_POSSUI_EXPERIENCIA: 21,
    VOCE_DA_SUPERVISAO_CLINICA: 22,
    VOCE_EXERCE_ALGUMA_FUNCAO_COMO_TERAPEUTA: 23,
    VOCE_JA_FOI_SUJEITO_A_ACOES_DISCIPLINARES_ATUANDO_COMO_TERAPEUTA: 24,
    VOCE_PRETENDE_ATENDER_EM_QUAIS_IDIOMAS: 25,
    VOCE_TEM_UM_SUPERVISOR_CLINICO: 26
} as Record<string, any>;