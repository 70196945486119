
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import store from '@/store';
import Form from '@/components/orienteme/CustomForm.vue';
import Rules from '@/utils/rules';
import signup from '@/mixins/signup';
import Notification from '@/mixins/notification';
import answers from '@/mixins/answers';
import { AVALIABILITY_FORM, STEPS } from '@/constants';
import { IFieldEvent }  from '@/interfaces/field';

export default defineComponent({
  components: { Form },
  mixins: [signup, Notification, answers],
  data() {
    return {
      AVALIABILITY_FORM,
      loading: false,
      lockState: false,
      form: {
        availability: null,
        emailCheckFrequency: null,
        languages: [],
        ageGroup: [],
      } as any,
    };
  },
  computed: {
    ...mapGetters(['answersByValue', 'answersByKey']),
    formRules() {
      return {
        availability: [Rules.required()],
        emailCheckFrequency: [Rules.required()],
        languages: [Rules.required()],
        ageGroup: [Rules.required()],
      };
    },
    filterFieldsByProfession() {
      return AVALIABILITY_FORM.filter((el) => {
        const targets = el.targets as string[];
        return targets.includes(this.profession.target);
      });
    }
  },
  methods: {
    ...mapActions(['fetchAnswers',
    'postAvailability',
    'fetchUserAnswers',
    'isFormFinished',
    'refreshStateApp',
    'fetchGlobalAnswers']),
    async handleSubmitForm(): Promise<void> {
      if(this.lockState) return;

      this.lockState = true;
      this.loading = true;

      const uuid = this.$route.query.uuid as string;
      const answers = this.buildRequest(
        uuid,
        AVALIABILITY_FORM,
        this.profession.target,
        this.$data.form,
        'availabilityAnswers'
      );
      try{
        await this.postAvailability(answers);
        this.notifySuccess('Dados salvo com sucesso!');
        const isFinished = await this.isFormFinished(uuid);
        if(isFinished){
          this.$router.push({
              name: 'signup.thanks.finish',
              query: this.$route.query
          });
        }else{
          this.warn('Alguns dados estão faltando, favor valide os dados pessoais');
          this.redirectStep(STEPS['Dados Pessoais']);
        }
      }
      catch(err: any){
        this.notify(err);
      }
      finally{
        this.lockState = false;
        this.loading = false;
      }
    },
    handleFieldChange(event: IFieldEvent): void{
      this.form[event.field.prop] = event.e;
    }
  },
  async mounted() {
    this.loading = true;
    this.refreshStateApp();
    try{
      const professionType = this.profession.value;
      await this.fetchGlobalAnswers({arrayAnswers: store.state.availabilityAnswers,
        professionType,
        uuid: this.$route.query.uuid,
        storeQuestions: 'availabilityAnswers',
        storeUserAnswers: 'availability'});
    }
    catch(ex){
      this.notify(ex);
    }
    finally{
      this.loading = false;
      this.renderUserAnswers(store.state.user.availability,
        AVALIABILITY_FORM,
        this.form,
        'availabilityAnswers');
    }
  }
});
