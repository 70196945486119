import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "label" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "subtitle-upload" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_UploadButton = _resolveComponent("UploadButton")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createBlock(_component_el_col, {
    class: "custom-fields",
    span: _ctx.field.col ?? 24,
    xs: 24
  }, {
    default: _withCtx(() => [
      (_ctx.field.type !== 'upload')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            class: _normalizeClass({'non-input' :  !_ctx.field.removePadding && _ctx.field.type != 'input'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("b", null, _toDisplayString(_ctx.field[`title__${_ctx.targetRendering}`] ?? _ctx.field.title), 1)
                    ]),
                    _: 1
                  }),
                  (!['input', 'select'].includes(_ctx.field.type))
                    ? (_openBlock(), _createBlock(_component_el_col, {
                        key: 0,
                        span: 24,
                        class: "label"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.field.subtitle), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      (_ctx.field.type.includes('radio'))
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            class: _normalizeClass({'scroll' : _ctx.hasToScroll}),
            prop: _ctx.field.prop
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio_group, {
                onChange: _ctx.handleChange,
                "model-value": _ctx.fieldValue,
                class: _normalizeClass({ 'radio-button-group': _ctx.field.type === 'radioButton' })
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.externalOptions, (option, idx) => {
                    return (_openBlock(), _createBlock(_component_el_radio, {
                      class: _normalizeClass({ 'radio-button-item': _ctx.field.type === 'radioButton' }),
                      key: `${_ctx.field.prop}-${idx}`,
                      label: option.value
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(option.value), 1)
                      ]),
                      _: 2
                    }, 1032, ["class", "label"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["onChange", "model-value", "class"])
            ]),
            _: 1
          }, 8, ["class", "prop"]))
        : _createCommentVNode("", true),
      (_ctx.field.type === 'checkbox')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 2,
            class: _normalizeClass({'scroll' : _ctx.hasToScroll}),
            style: _normalizeStyle({'height': _ctx.field.height + 'px' ?? 'auto'}),
            prop: _ctx.field.prop
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_checkbox_group, {
                "model-value": _ctx.fieldValue,
                onChange: _ctx.handleChange
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.externalOptions, (option) => {
                    return (_openBlock(), _createBlock(_component_el_checkbox, {
                      key: option.key,
                      label: option.value
                    }, null, 8, ["label"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["model-value", "onChange"])
            ]),
            _: 1
          }, 8, ["class", "style", "prop"]))
        : _createCommentVNode("", true),
      (_ctx.field.type === 'select')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 3,
            class: _normalizeClass({'scroll' : _ctx.hasToScroll}),
            prop: _ctx.field.prop
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                filterable: "",
                "model-value": _ctx.fieldValue,
                onChange: _ctx.handleChange,
                "no-match-text": "Nennhum resultado",
                placeholder: _ctx.field.placeholder ?? 'Selecionar'
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.externalOptions, (option) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: option.key,
                      label: option.value,
                      value: option.key
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["model-value", "onChange", "placeholder"]),
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.field.subtitle), 1)
            ]),
            _: 1
          }, 8, ["class", "prop"]))
        : _createCommentVNode("", true),
      (_ctx.field.type === 'textarea')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 4,
            prop: _ctx.field.prop
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                onInput: _ctx.handleChange,
                "model-value": _ctx.fieldValue,
                type: "textarea",
                placeholder: _ctx.field.placeholder
              }, null, 8, ["onInput", "model-value", "placeholder"])
            ]),
            _: 1
          }, 8, ["prop"]))
        : _createCommentVNode("", true),
      (_ctx.field.type === 'input')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 5,
            prop: _ctx.field.prop
          }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_el_input, {
                onInput: _ctx.handleChange,
                "model-value": _ctx.fieldValue,
                type: "text",
                maxlength: _ctx.field.maxlength,
                placeholder: _ctx.field.placeholder,
                onKeyup: _withKeys(_ctx.searchAddress, ["enter"]),
                onBlur: _ctx.searchAddress
              }, null, 8, ["onInput", "model-value", "maxlength", "placeholder", "onKeyup", "onBlur"]), [
                [_directive_maska, _ctx.field.mask]
              ]),
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.field.subtitle), 1)
            ]),
            _: 1
          }, 8, ["prop"]))
        : _createCommentVNode("", true),
      (_ctx.field.type === 'upload')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 6,
            prop: _ctx.field.prop
          }, {
            default: _withCtx(() => [
              _createElementVNode("b", null, _toDisplayString(_ctx.field.title), 1),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.field.subtitle), 1),
              _createVNode(_component_UploadButton, {
                limit: _ctx.field.limit,
                model: _ctx.fieldValue,
                onUpload: _ctx.handleChange,
                onRemove: _ctx.pictureRemove
              }, null, 8, ["limit", "model", "onUpload", "onRemove"])
            ]),
            _: 1
          }, 8, ["prop"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["span"]))
}