export interface IProfessions {
    psicologos: any,
    juridicos: any,
    financeiros: any,
    sociais: any,
    nutricionistas: any,
    medicos: any,
    coaches: any,
    personaltrainers: any,
    orientacaofisica: any,
}

export const PROFESSIONS = {
    psicologos: {
        label: 'Psicologia',
        target: 'psicologos',
        value: 1
    },
    juridicos: {
        label: 'Juridico',
        target: 'juridicos',
        value: 2
    },
    financeiros: {
        label: 'Financeiro',
        target: 'financeiros',
        value: 3
    },
    sociais: {
        label: 'Social',
        target: 'sociais',
        value: 4
    },
    nutricionistas: {
        label: 'Nutrição',
        target: 'nutricionistas',
        value: 5
    },
    medicos: {
        label: 'Medicina',
        target: 'medicos',
        value: 6
    },
    coaches: {
        label: 'Coach',
        target: 'coaches',
        value: 7
    },
    personaltrainers: {
        label: 'PersonalTraining',
        target: 'personaltrainers',
        value: 8
    },
    orientacaofisica: {
        label: 'Orientação Física',
        target: 'orientacaofisica',
        value: 9
    }
} as IProfessions;

export interface IProfession {
    label: string,
    target: 'psicologos'| 'juridicos' | 'financeiros' | 'sociais' | 'nutricionistas' | 'medicos' | 'coaches' | 'orientacaofisica'
    value: number,
}