
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';
import signup from '@/mixins/signup';

export default defineComponent({
  mixins: [signup],
  computed: {
    ...mapState(['user']),
    name(): string {
      return this.user.name?.split(' ')[0];
    },
    segment(): string {
      const segments = {
        psicologos: 'Psicologia',
        nutricionistas: 'Nutrição',
        orientacaofisica: 'Orientação Física',
      } as Record<string, string>;

      return segments[this.$route.params.profession as string];
    },
    firstThankYou(): boolean {
      return !this.$route.path.endsWith('finalizacao');
    }
  },
  methods: {
    ...mapActions(['setStepsVisible']),
    goToWebSite(): void {
      window.location.href = process.env.VUE_APP_WEBSITE_URL as string;
    }
  },
  mounted(){
    this.setStepsVisible(false);
  }
});
