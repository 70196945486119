import {  IField, IOptionType } from '@/interfaces/field';
import {PROFESSIONS} from '../../constants';
const allProfessions = [
    PROFESSIONS.psicologos.target,
    PROFESSIONS.nutricionistas.target,
    PROFESSIONS.orientacaofisica.target
];

export const AVALIABILITY_FORM = [
    {
        targets: allProfessions,
        prop: 'availability',
        title: 'Quanto tempo você poderá dispor para trabalhar com a plataforma diariamente?',
        subtitle: 'Selecione apenas uma opção',
        type: 'radio',
        options: {
            storeGetter: 'availabilityAnswers',
            key: 'QUANTO_TEMPO_VOCE_PODERA_DISPOR_PARA_TRABALHAR_COM_A_PLATAFORMA_DIARIAMENTE'
        } as IOptionType
    },
    {
        targets: allProfessions,
        prop: 'emailCheckFrequency',
        title: 'Com que frequência você checa seu e-mail?',
        subtitle: 'Selecione apenas uma opção',
        type: 'radio',
        options: {
            storeGetter: 'availabilityAnswers',
            key: 'COM_QUE_FREQUENCIA_VOCE_CHECA_SEU_E_MAIL'
        } as IOptionType
    },
    {
        targets: allProfessions,
        prop: 'languages',
        title: 'Você pretende atender em quais idiomas?',
        subtitle: 'Indique todas as aplicáveis',
        type: 'checkbox',
        options: {
            storeGetter: 'availabilityAnswers',
            key: 'VOCE_PRETENDE_ATENDER_EM_QUAIS_IDIOMAS'
        } as IOptionType
    },
    {
        targets: allProfessions,
        prop: 'ageGroup',
        title: 'Quais faixas etárias você tem interesse em atender online?',
        subtitle: 'Indique todas as aplicáveis',
        type: 'checkbox',
        options: {
            storeGetter: 'availabilityAnswers',
            key: 'QUAL_IS__FAIXA_S__ETARIA_S__VOCE_TEM_INTERESSE_EM_ATENDER_ONLINE'
        } as IOptionType
    }
  ] as IField[];