import {IField, IOptionType} from '../../interfaces/field';
import {
    GENDERS, STATES, YES_NO_OPTION, ONLINE_SERVICES,
    INTERNET_SKILL
  } from '@/constants';
const targets =['nutricionistas', 'psicologos', 'orientacaofisica']; 
export const PERSONAL_FORM = {
    personalForm: [
        {
            targets,
            prop: 'name',
            title: 'Nome completo',
            subtitle: 'Por favor, preencha seu nome completo sem abreviações',
            type: 'input',
            col: 15,
        } as IField,
        {
            targets,
            prop: 'cpfCnpj',
            title: 'CPF',
            subtitle: 'Use apenas números exemplo 11122233344',
            type: 'input',
            col: 9,
            mask: '###.###.###-##',
            maxlength: 14
        } as IField,
        {
            targets,
            prop: 'dateOfBirth',
            title: 'Data de nascimento',
            subtitle: 'Use o formato DD/MM/AAAA',
            type: 'input',
            col: 8,
            mask: '##/##/####',
            maxlength: 10
        } as IField,
        {
            targets,
            prop: 'gender',
            title: 'Gênero',
            type: 'select',
            col: 8,
            placeholder: 'Selecionar',
            options: GENDERS,
            removePadding: true
        } as IField,
        {
            targets,
            prop: 'phoneNumber',
            title: 'Telefone',
            subtitle: 'Ex. 11 987654321',
            type: 'input',
            col: 8,
            mask: ['(##) ####-####', '(##) #####-####']
        } as IField,
        {
            targets: ['psicologos'],
            prop: 'professionalCouncilNumber',
            title: 'Número do CRP',
            subtitle: 'Informe número e letras',
            type: 'input',
            col: 8,
        } as IField,
        {
            targets: ['nutricionistas'],
            prop: 'professionalCouncilNumber',
            title: 'Número do CRN',
            subtitle: 'Informe número e letras',
            type: 'input',
            col: 8,
        } as IField,
        {
            targets: ['orientacaofisica'],
            prop: 'professionalCouncilNumber',
            title: 'Número do CREF',
            subtitle: 'Informe número e letras',
            type: 'input',
            col: 8,
        } as IField,
        {
            targets: ['psicologos'],
            prop: 'professionalCouncilState',
            title: 'UF do CRP',
            type: 'select',
            col: 8,
            options: STATES,
            removePadding: true
        } as IField,
        {
            targets: ['nutricionistas'],
            prop: 'professionalCouncilState',
            title: 'UF do CRN',
            type: 'select',
            col: 8,
            options: STATES,
            removePadding: true
        } as IField,
        {
            targets,
            prop: 'hasCriminalRecords',
            title: 'Possui antecedentes criminais?',
            type: 'select',
            col: 8,
            options: YES_NO_OPTION,
            removePadding: true
        } as IField,
    ],
    feedbackForm: [
        {
            targets,
            prop: 'referencePersonName',
            title: 'Nome',
            type: 'input',
            col: 8,
        } as IField,
        {
            targets,
            prop: 'referencePersonPhoneNumber',
            title: 'Telefone',
            type: 'input',
            mask: ['(##) ####-####', '(##) #####-####'],
            maxlength: 15,
            col: 8,
        } as IField,
        {
            targets,
            prop: 'referencePersonEmail',
            title: 'E-mail',
            type: 'input',
            col: 8,
        } as IField,
        {
            targets,
            prop: 'onlineService',
            title: 'Por que o atendimento online te interessa?',
            subtitle: 'Indique todas as alternativas',
            type: 'checkbox',
            options: ONLINE_SERVICES,
        } as IField,
        {
            targets,
            prop: 'howDidYouKnowPlataform',
            title: 'Como você descobriu a orienteme?',
            type: 'select',
            col: 8,
            options:  {
                storeGetter: 'personalDataAnswers',
                key: 'COMO_VOCE_DESCOBRIU_A_ORIENTEME'
              } as IOptionType,
            placeholder: 'Selecionar'
        } as IField,
        {
            targets,
            prop: 'howIsYourInternetConnection',
            title: 'Como é sua conexão de internet?',
            type: 'select',
            col: 8,
            options:  {
                storeGetter: 'personalDataAnswers',
                key: 'MINHA_CONEXAO_COM_A_INTERNET_E'
              } as IOptionType,
            placeholder: 'Selecionar'
        } as IField,
        {
            targets,
            prop: 'whatOperationSystemDoYouUse',
            title: 'Qual sistema operacional você usa?',
            type: 'select',
            col: 8,
            options:  {
                storeGetter: 'personalDataAnswers',
                key: 'QUE_SISTEMA_OPERACIONAL_DE_SMARTPHONE_VOCE_USA'
                } as IOptionType,
            placeholder: 'Selecionar'
        } as IField,
        {
            targets,
            prop: 'similarityDifferenceOnLineService',
            title: 'Na sua opinião, quais são as semelhanças e diferenças entre o atendimento presencial e o atendimento online?',
            type: 'textarea',
            col: 24,
            placeholder: 'Escreva aqui...'
        } as IField,
        {
            targets,
            prop: 'whyDoYouWantToMeetOnThePlatform',
            title: 'Por que você quer atender na orienteme?',
            type: 'textarea',
            col: 24,
            placeholder: 'Escreva aqui...'
        } as IField,
        {
            targets,
            prop: 'internetSkillLevel',
            title: 'De 1 a 5 o quanto você considera o sua habilidade na internet?',
            type: 'radioButton',
            options: INTERNET_SKILL,
            col: 24,
        } as IField,
    ]
};