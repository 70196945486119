export interface IField {
    targets: string[], // 'psicologos' | 'nutricionistas'
    prop: string,
    title?: string,
    title__nutricionistas?: string,
    title__psicologos?: string,
    subtitle?: string,
    placeholder?: string,
    type: 'checkbox' | 'radio' | 'radioButton' | 'textarea' | 'input' | 'select' | 'upload',
    col?: number | undefined | null,
    options?: IOptionType | IOption[] | string,
    mask?: any,
    maxlength?: number
    removePadding?: boolean,
    limit?: number,
    minimum?: number;
    height?: number;
}

export interface IOptionType {
    storeGetter: any[] | string
    key?: string,
}
export interface IFieldEvent {
    e: any,
    field: IField
}
export interface IOption {
    key: string,
    value: string
}

export class Option implements IOption  {
    constructor(key: string | boolean, value: string){
        this.key = key;
        this.value = value;
    }
    key: any;
    value: string;
}