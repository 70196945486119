
import {
    defineComponent
} from 'vue';
import store from '@/store';
import { IOption } from '@/interfaces/field';
import UploadButton from '@/components/orienteme/UploadButton.vue';

export default defineComponent({
    emits: ['change', 'pictureRemove', 'searchZipCode'],
    name: 'custom-field',
    components: { UploadButton },
    store,
    props: {
        field: {
            type: Object,
            required: true
        },
        fieldValue: {
            required: true
        },
        targetRendering: {
            type: String,
            required: true
        }
    },
    computed:{
        externalOptions: function(): IOption[]{
            const options = this.field.options;
            if(typeof(options) === 'string'){
                return store.getters[options];
            }
            if(!options?.key){
                return options?.storeGetter ? options?.storeGetter : options;
            }
            if(options?.key){
                const {storeGetter, key} = options;
                return store.getters.answers(storeGetter, key);
            }
            return options;
        },
        hasToScroll: function(){
            return this.externalOptions.length > 20;
        }
    },
    methods: {
        handleChange: function (e: any) {
            this.$emit('change', {
                e,
                field: this.field
            });
        },
        searchAddress() {
            if (this.field.prop === 'zipCode') {
                this.$emit('searchZipCode', {
                    e: this.fieldValue,
                    field: this.field
                });
            }
        },
        handlePictureRemove: function (e: any) {
            this.$emit('pictureRemove', {
                e,
                field: this.field
            });
        }
    },
});
