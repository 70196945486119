
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Form from '@/components/orienteme/CustomForm.vue';
import signup from '@/mixins/signup';
import notification from '@/mixins/notification';
import Rules from '@/utils/rules';
import store from '@/store';
import { ADDRESS_FORM, STEPS } from '@/constants';

import axios from 'axios';

export default defineComponent({
    components: {
        Form
    },
    store,
    mixins: [signup, notification],                                                                                 
    data() {
        return {
            isWaiting: false,
            loading: false,
            lockState: false,
            form: {
                publicPlace: null,
                number: null,
                zipCode: null,
            } as any,
        };
    },
    methods: {
        ...mapActions(['postAddress', 'fetchAddress']),
        async handleSubmitForm(): Promise<void>{
            if(this.lockState) return;

            this.lockState = true;
            this.loading = true;

            this.form.uuid = this.$route.query.uuid as string;
            try {
                this.form.country = 'BR';
                this.form.uuid = this.$route.query.uuid as string;
                await this.postAddress(this.form);
                this.notifySuccess('Endereço salvo com sucesso!');
                this.redirectStep(STEPS['Dados Profissionais']);
            } catch (err: any) {
                this.notify(err);
            }
            finally{
              this.lockState = false;
              this.loading = false;
            }
        },
        handleFieldChange(event: any): void {
            this.form[event.field.prop] = event.e;
        },
        searchAddress() {
            const zipCodeLength = this.form.zipCode?.replace('-', '').length;

            if (this.form.zipCode && zipCodeLength === 8) {
                axios.get(`https://viacep.com.br/ws/${this.form.zipCode}/json/`)
                .then(response => {
                    const addressData: any = response.data;

                    if (addressData.erro && !this.isWaiting) {
                        this.notifyError('CEP não encontrado', 2000);
                        this.isWaiting = true;

                        // set timeout to avoid multiple requests
                        setTimeout(() => {
                            this.isWaiting = false;
                        }, 5000);
                    } 
                    
                    this.form.publicPlace = addressData.logradouro;
                    this.form.neighborhood = addressData.bairro;
                    this.form.city = addressData.localidade;
                    this.form.state = addressData.uf;
                })
                .catch(error => {
                    console.error('Erro ao buscar endereço:', error);
                });
             } 
        }
    },
    computed: {
    ...mapGetters(['isDebug', 'isNewUser', 'cleanAddress']),
        formRules() {
            return {
                publicPlace: [Rules.required()],
                zipCode: [Rules.required()],
                city: [Rules.required()],
                state: [Rules.required()],
                number: [Rules.required()],
            };
        },
        filterFieldsByProfession() {
            return this.fields.filter((el: any) => {
                const targets = el.targets as string[];
                return targets.includes(this.profession.target);
            });
        },
        fields() {
            return ADDRESS_FORM;
        }
    },
    mounted: async function () {
        this.loading = true;
        const uuid = this.$route.query.uuid as string;
        try {
            if(!this.isNewUser){
                await this.fetchAddress(uuid);
                const address = this.cleanAddress;
                this.form = {
                    ...address,
                };
            }
        } catch (err: any) {
            this.notifyError('Algo deu errado, favor tentar novamente', 0);
        } finally {
            this.loading = false;
        }
    }
});
