import { defineComponent } from 'vue';

export default defineComponent({
  data(){
		return {
			form: {

      } as any
    };
  },
  methods: {
		handleUploadPicture(picture: any, key: string): void {
      if(!this.form[key]) this.form[key] = [];
      this.form[key].push(picture);
		},
		handleRemovePicture(key: string): void {
      this.form[key].pop();
		},
  }
});