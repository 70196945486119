import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import router from '@/router';
import store from '@/store';

/**
 * Creates and exports an Axios instance
 */
const baseURL = process.env.VUE_APP_API_ENTITY_PROFESSIONAL as string;

const http = axios.create({ baseURL });

/**
 * Add token to header request
 */
http.interceptors.request.use((req: AxiosRequestConfig): AxiosRequestConfig => {
  const Authorization = `Bearer ${store.state.token}`;
  req.headers = { ...req.headers, Authorization };
  return req;
});

/**
 * Log requests for development
 */
if(process.env.VUE_APP_ENV === 'development'){
  http.interceptors.request.use((req: AxiosRequestConfig): AxiosRequestConfig => {
    console.log('Intercept Request ' + req.url);
    console.log(req);
    return req;
  });
  http.interceptors.response.use((req: AxiosResponse): AxiosRequestConfig => {
    console.log('Intercept Response ' +  req.config.url);
    console.log(req);
    return req;
  });
}

/**
 * Monitor 401 to send to login page
 */
 http.interceptors.response.use(({ data }) => data, async (err) => {
  let errorCode = 500;
  let wrappedError;

  if (err.response) {
    errorCode = err.response.status;
    wrappedError = err.response.data;
  }

  // try to refresh token
  if (errorCode === 401) {
    return router.replace({ name: 'auth.signin' });
  }

  throw wrappedError || err;
});

export default http;
