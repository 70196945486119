
import {
    defineComponent
} from 'vue';
import {
    mapActions
} from 'vuex';
import {
    ElNotification
} from 'element-plus';
import Form from '@/components/orienteme/CustomForm.vue';
import Rules from '@/utils/rules';
import RulesI from '@/interfaces/Rules';
import signup from '@/mixins/signup';
import { BASIC_FORM } from '@/constants';

export default defineComponent({
    mixins: [signup],
    components: {
        Form
    },
    data() {
        return {
            loading: false,
            lockState: false,
            form: {
                name: '',
                cpfcnpj: '',
                email: '',
                phoneNumber: '',
                professionalCouncilNumber: '',
                professionalCouncilState: '',
                profession: 0,
                language: '1',
                uuidReference: null,
            } as any,
        };
    },
    computed: {
        formRules(): Record<string, Array<RulesI>> {
            return {
                name: [
                    Rules.required(),
                ],
                email: [
                    Rules.required(),
                    Rules.email()
                ],
                phoneNumber: [
                    Rules.required(),
                ],
                cpfcnpj: [
                    Rules.required(),
                ],
                professionalCouncilNumber: [
                    Rules.required(),
                ],
                professionalCouncilState: [
                    Rules.required()
                ],
            };
        },
        filterFieldsByProfession() {
            return BASIC_FORM.filter((el: any) => {
                const targets = el.targets as string[];
                return targets.includes(this.profession.target);
            });
        }
    },
    methods: {
        ...mapActions(['postBasicData', 'setStepsVisible']),
        handleFieldChange(event: any): void {
            this.form[event.field.prop] = event.e;
        },
        async submitForm(): Promise<void> {
            try {
                // if has request going on
                // if(this.lockState) return;

                this.lockState = true;
                this.loading = true;
                this.form.profession = this.profession.value;
                this.form.uuidReference = this.$route.query.uuidReference;
                await this.postBasicData(this.form);
                this.$router.push({
                    name: 'signup.thanks'
                });
            } catch (err: any) {
                ElNotification({
                    title: 'Atenção',
                    message: err.message,
                    type: 'error',
                });
            } finally {
                this.loading = false;
                this.lockState = false;
            }
        },
    },
    mounted() {
        this.setStepsVisible(false);
    }
});
