
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import UploadButton from '@/components/orienteme/UploadButton.vue';
import CustomField from '@/components/orienteme/CustomField.vue';
import Rules from '@/utils/rules';
import signup from '@/mixins/signup';
import notification from '@/mixins/notification';
import UploadImage from '@/mixins/upload-image';
import store from '@/store';
import { PERSONAL_FORM, ONLINE_SERVICES, INTERNET_SKILL, STEPS } from '@/constants';
import answers from '@/mixins/answers';

export default defineComponent({
  components: { UploadButton, CustomField },
  mixins: [signup, notification, UploadImage, answers],
  store,
  data() {
    return {
      loading: false,
      lockState: false,

      form: {
        onlineService: [],
        internetSkillLevel: null,
        pictureProfessional: [],
        pictureIdentification: [],
      } as any,
    PERSONAL_FORM,
    };
  },
  computed: {
    ...mapGetters(['isDebug', 'isNewUser']),
    formRules() {
      return {
        name: [Rules.required()],
        cpfCnpj: [Rules.required()],
        dateOfBirth: [Rules.required(), Rules.date()],
        gender: [Rules.required()],
        phoneNumber: [Rules.required()],
        professionalCouncilNumber: [Rules.required()],
        professionalCouncilState: [Rules.required()],
        hasCriminalRecords: [Rules.required()],
        howDidYouKnowPlataform: [Rules.required()],
        howIsYourInternetConnection: [Rules.required()],
        whatOperationSystemDoYouUse: [Rules.required()],
        internetSkillLevel: [Rules.required(), Rules.minimum(1)],
        similarityDifferenceOnLineService: [Rules.required()],
        whyDoYouWantToMeetOnThePlatform: [Rules.required()],
        pictureIdentification: [Rules.required()],
        pictureProfessional: [Rules.required()],
      };
    },
    uuid() {
      return this.$route.query.uuid;
    },
    filterPersonalForm() {
      return PERSONAL_FORM.personalForm.filter((el: any) => {
          const targets = el.targets as string[];
          return targets.includes(this.profession.target);
      });
    },
    filterFeedbackForm() {
      return PERSONAL_FORM.feedbackForm.filter((el: any) => {
          const targets = el.targets as string[];
          return targets.includes(this.profession.target);
      });
    },
  },
  methods: {
    ...mapActions(['postPersonalData',
      'sendProfilePicture',
      'sendIdentificationDocumentPicture',
      'fetchPersonalData',
      'fetchGlobalAnswers']),

    async submitForm(formName: string): Promise<void> {
      if(this.lockState) return;
      this.lockState = true;
      const refs = this.$refs[formName] as Record<string, any>;

      refs.validate(async (valid: boolean) => {
        if (valid) {
          this.form.profession = this.profession.value;
          this.form.uuid = this.$route.query.uuid;
          try{
            const requestBody = JSON.parse(JSON.stringify(this.form));
            await this.postPersonalData(requestBody);
            let sendProfilePicture = null, sendIdentificationDocumentPicture = null;
            if(!this.form.pictureProfessional[0].sync){
              sendProfilePicture = this.sendProfilePicture({
                picture: this.form.pictureProfessional,
                uuid: this.uuid
              });
            }
            if(!this.form.pictureIdentification[0].sync){
              sendIdentificationDocumentPicture = this.sendIdentificationDocumentPicture({
                picture: this.form.pictureIdentification,
                uuid: this.uuid
              });
            }
            await Promise.all([sendProfilePicture, sendIdentificationDocumentPicture]);
            this.notifySuccess('Dados pessoais salvo com sucesso!');
            this.redirectStep(STEPS.Endereço);
          }
          catch(err: any){
            this.notify(err);
          }
        }else{
          this.warn('Favor preencher todos os campos obrigatórios');
        }
        this.lockState = false;
      });
    },
    handleFieldChange(event: any): void {
      this.form[event.field.prop] = event.e;
    },
    builder(rawData: any) {
      const form = {...rawData} as any;
      return {
        build(){
          // como você conhece a plataforma
          this.clearZero('howDidYouKnowPlataform');

          // como é sua conexão com internet
          this.clearZero('howIsYourInternetConnection');

          // qual sistema operacional usa
          this.clearZero('whatOperationSystemDoYouUse');

          return form;
        },
        dateOfBirth(){
          if(rawData.dateOfBirth){
            const date = new Date(rawData.dateOfBirth);
            form.dateOfBirth = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          }
          return this;
        },
        gender(){
          if(!rawData.gender || rawData.gender === 0){
            form.gender = null;
          }else{
            form.gender = rawData.gender.toString();
          }
          return this;
        },
        onlineService(){
          form.onlineService = [];
          for(let option of ONLINE_SERVICES){
            if(rawData[option.key])
              form.onlineService.push(option.value);
          }
          return this;
        },
        internetSkillLevel(){
          if(rawData.internetSkillLevel > 0)
            form.internetSkillLevel = INTERNET_SKILL[rawData.internetSkillLevel - 1].value;
          return this;
        },
        clearZero(field: string): void {
          if(form[field] === 0)
            form[field] = null;
          else
            form[field] = form[field].toString();
        },
        pictureProfessional(){
          if(rawData.profilePictureUuid){
            form.pictureProfessional = [{
              name: rawData.profilePictureName,
              uid: rawData.profilePictureUuid,
              sync: true,
              raw: {
                type: rawData.profilePictureType,
                uid: rawData.profilePictureUuid
              }
            }];
          }
          return this;
        },
        pictureIdentification(){
          if(rawData.identificationDocumentUuid){
            form.pictureIdentification = [{
              name: rawData.identificationDocumentName,
              uid: rawData.identificationDocumentUuid,
              sync: true,
              raw: {
                type: rawData.identificationDocumentType,
                uid: rawData.identificationDocumentUuid
              }
            }];
          }
          return this;
        }
      };
    },
  },
  mounted: async function() {
    this.loading = true;
    try{
      const professionType = this.profession.value;
      await this.fetchPersonalData(this.uuid);
      await this.fetchGlobalAnswers(
        {
          arrayAnswers: store.state.personalDataAnswers,
          professionType,
          uuid: this.$route.query.uuid,
          storeQuestions: 'personalDataAnswers',
          storeUserAnswers: 'personalData'
        });
      if(!this.isNewUser){

        this.form = this.builder({
          ...store.state.user.personalData,
          ...store.state.user.initialData
        })
          .dateOfBirth()
          .gender()
          .onlineService()
          .internetSkillLevel()
          .pictureProfessional()
          .pictureIdentification()
          .build();
          this.renderUserAnswers(store.state.user.personalData,
            PERSONAL_FORM.feedbackForm,
            this.form,
            'personalDataAnswers');
      }else{
        this.form = {
          ...this.form,
          ...store.state.user.personalData,
          ...store.state.user.initialData
        };
        // clear dummy data
        this.form.dateOfBirth = null;
        this.form.gender = null;
        this.form.howDidYouKnowPlataform = null;
        this.form.howIsYourInternetConnection = null;
        this.form.whatOperationSystemDoYouUse = null;
        this.form.whyDoYouWantToMeetOnThePlatform = null;
        this.form.hasCriminalRecords = null;
      }
    }
    catch(err: any){
      console.log(err);
      this.notifyError('Algo deu errado, favor tentar novamente', 0);
    }
    finally{
      this.loading = false;
    }
  }
});
