import { IOption, Option } from '@/interfaces/field';
// list of all brazilian states
      export const STATES = [
          new Option('AC', 'Acre'),
          new Option('AL', 'Alagoas'),
          new Option('AP', 'Amapá'),
          new Option('AM', 'Amazonas'),
          new Option('BA', 'Bahia'),
          new Option('CE', 'Ceará'),
          new Option('DF', 'Distrito Federal'),
          new Option('ES', 'Espírito Santo'),
          new Option('GO', 'Goiás'),
          new Option('MA', 'Maranhão'),
          new Option('MT', 'Mato Grosso'),
          new Option('MS', 'Mato Grosso do Sul'),
          new Option('MG', 'Minas Gerais'),
          new Option('PA', 'Pará'),
          new Option('PB', 'Paraíba'),
          new Option('PR', 'Paraná'),
          new Option('PE', 'Pernambuco'),
          new Option('PI', 'Piauí'),
          new Option('RJ', 'Rio de Janeiro'),
          new Option('RN', 'Rio Grande do Norte'),
          new Option('RS', 'Rio Grande do Sul'),
          new Option('RO', 'Rondônia'),
          new Option('RR', 'Roraima'),
          new Option('SC', 'Santa Catarina'),
          new Option('SP', 'São Paulo'),
          new Option('SE', 'Sergipe'),
          new Option('TO', 'Tocantins')
      ] as IOption[];