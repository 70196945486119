import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import Maska from 'maska';
import './assets/styles/main.scss';
import './components/orienteme/styles.scss';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

const SENTRY_DSN = process.env.VUE_APP_SENTRY_DNS;

const app = createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(Maska);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.mount('#app');


Sentry.init({
  app,
  dsn: SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['cadastroprofissionais.orienteme.dev.br', /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

