
export default class Helpers {
  static onlyNumbers(value: string): string {
    if(typeof(value) === 'number')return value;
    if(!value) return value;
    return value.replace(/\D/g, '');
  }
  static distinctString(value: string, index: number, self: string[]): boolean {
    return self.indexOf(value) === index;
  }
  static flatten(array: any[]): string[] {
    return [].concat(...array);
  }
  static toDate(date: string): string {
    return new Date(date).toLocaleDateString();
  }
  static toJsonDate(date: string): string {
    try{
      const DateParts = date.split('/');
      return new Date(`${DateParts[1]}/${DateParts[0]}/${DateParts[2]}`).toISOString();
    }
    catch(exc){
      console.log(exc);
    }
    return date;
  }
  static async getBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const result = reader.result?.toString().split(',');
        resolve(result? result[1] : '');
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
 }
}
