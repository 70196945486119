import {IField} from '../../interfaces/field';
import {
     YES_NO_OPTION, PROFESSIONS
  } from '@/constants';

  const allProfessions = [
    PROFESSIONS.psicologos.target,
    PROFESSIONS.nutricionistas.target,
    PROFESSIONS.orientacaofisica.target
];
export const PROFESSIONAL_FORM = {
    graduationForm: [
        {
            targets: allProfessions,
            prop: 'courseId',
            title: 'Curso de graduação',
            subtitle: 'Qual sua formação',
            type: 'select',
            col: 8,
            options: 'courseListSelectOptions',
            removePadding: true
        } as IField,
        {
            targets: allProfessions,
            prop: 'institutionId',
            title: 'Instituição',
            type: 'select',
            col: 16,
            options: 'institutionsSelectOptions',
            removePadding: true
        } as IField,
        {
            targets: allProfessions,
            prop: 'graduateDate',
            title: 'Ano de conclusão da graduação',
            type: 'input',
            col: 8,
            mask: '##/##/####',
            maxlength: 10
        } as IField,
        {
            targets: allProfessions,
            prop: 'graduate',
            title: 'Titulação',
            subtitle: 'Indique a mais alta',
            type: 'select',
            col: 8,
            options: 'graduateListSelectOptions',
            removePadding: true
        } as IField,
        {
            targets: allProfessions,
            prop: 'graduation',
            title: 'Titulação Recebida',
            type: 'select',
            col: 8,
            options: 'graduationListSelectOptions',
            removePadding: true
        } as IField,
        {
            targets: allProfessions,
            prop: 'pictureGraduation',
            title: 'Foto diploma',
            subtitle: 'Garanta que a foto esteja legível',
            type: 'upload',
            col: 14,
            removePadding: true,
            limit: 1,
        } as IField
    ],
    occupationForm: [
        {
            targets: [PROFESSIONS.psicologos.target],
            prop: 'convenienceServiceAnyWhereTime',
            title: 'Você já foi sujeito a ações disciplinares atuando como terapeuta?',
            type: 'select',
            col: 14,
            options: YES_NO_OPTION,
            removePadding: false
        } as IField,
        {
            targets: [PROFESSIONS.nutricionistas.target],
            prop: 'hasEConcil',
            title: 'Possui o E-Nutricionista',
            title__psicologos: 'Possui o E-Psi',
            title__nutricionistas: 'Possui o E-Nutricionista',
            type: 'select',
            col: 10,
            options: YES_NO_OPTION,
            removePadding: false
        } as IField,
        {
            targets: allProfessions,
            prop: 'mostChallengingSituation',
            title: 'Como profissional, qual foi a situação mais desafiadora que você já enfrentou em um atendimento?',
            subtitle: 'Conte-nos um pouco sobre a situação e como a resolveu',
            placeholder: 'Escreva aqui...',
            type: 'textarea',
            col: 24,
            removePadding: true
        } as IField,
        {
            targets: [PROFESSIONS.psicologos.target, PROFESSIONS.orientacaofisica.target],
            prop: 'specialtys',
            title: 'Abordagem',
            title__psicologos: 'Qual é a sua abordagem terapêutica',
            subtitle: 'Indique todas as aplicáveis',
            type: 'checkbox',
            col: 24,
            options: 'specialtysSelectOptions'
        } as IField,
    ]
};