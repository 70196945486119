import {IField, IOptionType} from '../../interfaces/field';
import { PROFESSIONS } from '../enums/professions';
const allProfessions = [
  PROFESSIONS.psicologos.target,
  PROFESSIONS.nutricionistas.target,
  PROFESSIONS.orientacaofisica.target
];
export const EXPERIENCE_FORM = [
    {
      targets: allProfessions,
      prop: 'role',
      title: 'Você exerce alguma função na sua área?',
      title__nutricionistas: 'Você exerce alguma função como nutricionista?',
      title__psicologos: 'Você exerce alguma função como terapeuta?',
      subtitle: 'Indique todas as aplicáveis',
      type: 'checkbox',
      options: {
        storeGetter: 'experienceAnswers',
        key: 'VOCE_EXERCE_ALGUMA_FUNCAO_COMO_TERAPEUTA'
      } as IOptionType,
    } as IField,
    {
      targets: allProfessions,
      prop: 'years',
      title: 'Quantos anos de experiência com atendimento clínico você tem?',
      subtitle: 'Selecione apenas uma opção',
      type: 'radio',
      options: {
        storeGetter: 'experienceAnswers',
        key: 'QUANTOS_ANOS_DE_EXPERIENCIA_COM_ATENDIMENTO_CLINICO_VOCE_TEM'
      } as IOptionType,
    } as IField,
    {
      targets: allProfessions,
      prop: 'online',
      title: 'Já atendeu online? Se sim, por que meios?',
      subtitle: 'Selecione apenas uma opção',
      type: 'radio',
      options: {
        storeGetter: 'experienceAnswers',
        key: 'JA_ATENDEU_ONLINE_SE_SIM_POR_QUE_MEIOS'
      } as IOptionType,
    } as IField,
    {
      targets: [PROFESSIONS.psicologos.target],
      prop: 'themes',
      title: 'Selecione os principais temas da área em que você possui experiência/conhecimento:',
      subtitle: 'Selecione no mínimo 6 temas',
      type: 'checkbox',
      height: 390,
      options: {
        storeGetter: 'experienceAnswers',
        key: 'SELECIONE_ATE_10_TEMAS_PRINCIPAIS_EM_QUE_VOCE_POSSUI_EXPERIENCIA'
      } as IOptionType,
    } as IField,
    {
      targets: [PROFESSIONS.nutricionistas.target],
      prop: 'themes',
      title: 'Selecione os principais temas da área em que você possui experiência/conhecimento:',
      subtitle: 'Selecione no mínimo 6 temas',
      type: 'checkbox',
      height: 390,
      options: {
        storeGetter: 'experienceAnswers',
        key: 'SELECIONE_ATE_10_TEMAS_PRINCIPAIS_EM_QUE_VOCE_POSSUI_EXPERIENCIA'
      } as IOptionType,
    } as IField,
    {
      targets: [PROFESSIONS.orientacaofisica.target],
      prop: 'themes',
      title: 'Selecione os principais temas da área em que você possui experiência/conhecimento:',
      subtitle: 'Selecione no mínimo 6 temas',
      type: 'checkbox',
      height: 390,
      minimum: 6,
      options: {
        storeGetter: 'experienceAnswers',
        key: 'SELECIONE_ATE_10_TEMAS_PRINCIPAIS_EM_QUE_VOCE_POSSUI_EXPERIENCIA'
      } as IOptionType,
    } as IField,
    {
      targets: allProfessions,
      prop: 'patientsWeekly',
      title: 'Em média, quantos pacientes por semana você atende?',
      type: 'radioButton',
      options: {
        storeGetter: 'experienceAnswers',
        key: 'EM_MEDIA_QUANTOS_PACIENTES_POR_SEMANA_VOCE_ATENDE'
      } as IOptionType,
    } as IField,
    {
      targets: allProfessions,
      prop: 'patientsAge',
      title: 'Indique a faixa etária média dos seus pacientes atuais:',
      subtitle: 'Selecione apenas uma opção',
      type: 'radio',
      options: {
        storeGetter: 'experienceAnswers',
        key: 'INDIQUE_A_FAIXA_ETARIA_MEDIA_DOS_SEUS_PACIENTES_ATUAIS'
      } as IOptionType,
    } as IField,
    {
      targets:  [PROFESSIONS.psicologos.target],
      prop: 'haveSupervisor',
      title: 'Você tem um supervisor clínico?',
      type: 'radioButton',
      col: 12,
      options: {
        storeGetter: 'experienceAnswers',
        key: 'VOCE_TEM_UM_SUPERVISOR_CLINICO'
      } as IOptionType,
    } as IField,
    {
      targets: [PROFESSIONS.psicologos.target],
      prop: 'youAreSupervisor',
      title: 'Você dá supervisão clínica?',
      type: 'radioButton',
      col: 12,
      options: {
        storeGetter: 'experienceAnswers',
        key: 'VOCE_DA_SUPERVISAO_CLINICA'
      } as IOptionType,
    } as IField,
  ] as Array<IField>;