import { IOption, Option } from '@/interfaces/field';

export const ONLINE_SERVICES = [
    new Option('additionalVolumeUsers', 'Volume adicional de pacientes'),
    new Option('additionalIncome', 'Renda adicional'),
    new Option('convenienceServiceAnyWhereTime', 'Comodidade do atendimento em qualquer local e hora'),
    new Option('easeReceivingPeriodicUsersReports', 'Facilidade em receber relatórios periódicos de meus pacientes'),
    new Option('securityAutomatedPayment', 'Segurança em ter o pagamento automatizado pela plataforma'),
    new Option('securityHavingEncryptedConversations', 'Segurança em ter as conversas criptografadas'),
    new Option('others', 'Outros'),
 ] as IOption[];