
import { ISelectorElements } from '@/interfaces/selector-elements';
import {
    defineComponent, PropType
} from 'vue';

export default defineComponent({
  name: 'SelectorElements',
  emits: ['add', 'remove'],
  props: {
    list: {
      type: Object as PropType<ISelectorElements>,
      required: true
    }
  },
  methods: {
    handleClickAddGraduation: function (e: any){
      this.$emit('add', {e});
    },
    handleClickRemove: function(item: any){
      this.$emit('remove', {item});
    }
  }
});
