import {IField} from '../../interfaces/field';
import {STATES, PROFESSIONS} from '../../constants';
const allProfessions = [
    PROFESSIONS.psicologos.target,
    PROFESSIONS.nutricionistas.target,
    PROFESSIONS.orientacaofisica.target
];
export const ADDRESS_FORM = [
    {
        title: 'CEP',
        subtitle: 'Use o formato 00000-000',
        prop: 'zipCode',
        targets: allProfessions,
        type: 'input',
        col: 8,
        mask:'#####-###',
        maxlength:9
    } as IField,
    {
        title: 'Endereço',
        subtitle: 'Preenche seu endereço sem abreviações',
        prop: 'publicPlace',
        targets: allProfessions,
        type: 'input',
        col: 16,
    } as IField,
    {
        title: 'Número',
        subtitle: 'Número do imóvel',
        prop: 'number',
        targets: allProfessions,
        type: 'input',
        col: 9,
    } as IField,
    {
        title: 'Complemento',
        subtitle: 'Use esse campo para informações adicionais',
        prop: 'complement',
        targets: allProfessions,
        type: 'input',
        col: 15
    } as IField,

    {
        title: 'Bairro',
        subtitle: 'Informe seu bairro',
        prop: 'neighborhood',
        targets: allProfessions,
        type: 'input',
        col: 8
    } as IField,
    {
        title: 'Estado',
        prop: 'state',
        targets: allProfessions,
        type: 'select',
        col: 8,
        options: STATES
    }as IField,
    {
        title: 'Cidade',
        subtitle: 'Informe sua cidade',
        prop: 'city',
        targets: allProfessions,
        type: 'input',
        col: 8,
    }
];