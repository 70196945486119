export default class Rules {
  static required(): Record<string, unknown> {
    return {
      required: true,
      message: 'Campo obrigatório',
      trigger: 'blur',
    };
  }

  static email(): Record<string, unknown> {
    const error = (message: string) => message;
    const re = /^[a-z0-9._]+@[a-z0-9]+\.[a-z]/i;

    return {
      validator: (rule: unknown, value: string, callback: typeof error) => {
        if (!re.test(value)){
          return callback('Digite um e-mail válido');
        }

        return true;
      },
      trigger: 'blur',
    };
  }

  static date(): Record<string, unknown> {
    const error = (message: string) => message;
    return {
      validator: (rule: unknown, value: string, callback: typeof error) => {
        const DateParts = value.split('/');

        const isValidDate = Date.parse(`${DateParts[1]}/${DateParts[0]}/${DateParts[2]}`);

        if (isNaN(isValidDate)) {
          return callback('Digite uma data válida');
        }

        return true;
      },
      trigger: 'blur',
    };
  }

  static password(): Record<string, unknown> {
    return {
      min: 8,
      message: 'Mínimo 8 caracteres',
      trigger: 'blur',
    };
  }

  static minimum(min: number): Record<string, unknown> {
    const error = (message: string) => message;
    return {
      validator: (rule: unknown, value: any[], callback: typeof error) => {

        if (!value || value.length < min) {
          return callback(`Selecione no mínimo ${min} opção(ões)`);
        }

        return true;
      },
      trigger: 'blur',
    };
  }
}
