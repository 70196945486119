import { PROFESSION_QUESTIONS } from '@/constants';
import { IAnswer, IRequestPostAnswers } from '@/interfaces/answers';
import { IField, IOptionType } from '@/interfaces/field';
import Helpers from '@/utils/helpers';
import { defineComponent } from 'vue';
import store from '@/store';
import { mapGetters } from 'vuex';

const flatten = Helpers.flatten;
const distinct = Helpers.distinctString;

export default defineComponent({
  name: 'answersFormSupport',
  computed: {
    ...mapGetters(['answersByValue', 'answersByKey']),
  },
  methods: {
    // Replace the IDs for the label in dropdowns
    async renderUserAnswers(
      storeAnswers: any,
      formTemplate: any,
      vueFormData: any,
      storeObjectName: string) {
      formTemplate.forEach((field: IField) => {
        if (typeof (field.options) !== 'object') return;
        const option = field.options as IOptionType;
        if (!option.key) return;
        const questionId = PROFESSION_QUESTIONS[option?.key];
        let answers = null;
        if(Array.isArray(storeAnswers)){
          answers = storeAnswers.filter((y: any) => y.questionType === questionId)
           .map(({ answersId }: any) => answersId.map((z: any) => z.id));
        }else{
          answers = storeAnswers.userAnswers.filter((y: any) => y.questionType === questionId)
           .map(({ answersId }: any) => answersId.map((z: any) => z.id));
        }
        answers = flatten(answers).filter(distinct);
        const answerLabels = this.answersByKey(storeObjectName, option?.key, answers) as string[];
        if(field.type === 'select'){
          vueFormData[field.prop] = answers[0];
        }else if(field.type === 'checkbox'){
          vueFormData[field.prop] = answerLabels;
        }
        else{
          vueFormData[field.prop] = answerLabels[0];
        }
      });
    },
    buildRequest(
      uuid: string,
      jsonForm: IField[],
      professionTarget: string,
      form: Record<string, any>,
      storeObject: string
    ): IRequestPostAnswers {
      const payload = {
        uuid: uuid,
        answers: []
      } as IRequestPostAnswers;
      for (let prop of jsonForm) {
        prop = prop as any;
        if (prop.targets.includes(professionTarget) &&
          typeof (prop.options) === 'object') {
          const option = prop.options as IOptionType;
          const isOptionApiType = option.storeGetter != null && option.key != null;
          if (isOptionApiType) {
            if (option.key == null) continue;

            const questionType = PROFESSION_QUESTIONS[option.key];
            let userAnswers = form[prop.prop];

            userAnswers = typeof (userAnswers) === 'string' ? [userAnswers] : userAnswers;

            for (const userAnswer of userAnswers) {
              const answerId = store.getters.answersByValue(storeObject, option?.key, userAnswer);
              payload.answers.push({ questionType, answerId } as IAnswer);
            }
          }
        }
      }
      return payload;
    }
  }
});