export const STEPS = {

  'Dados Pessoais': {
    path: ':profession/dados-pessoais',
    name: 'signup.personaldata',
    id: 1,
    active: false,
    finished: false,
    inProgress: false,
    meta: {
      title: 'Dados Pessoais',
      status: 10
    }
  },
  'Endereço': {
    path: ':profession/endereco',
    name: 'signup.address',
    id: 2,
    active: false,
    finished: false,
    inProgress: false,
    meta: {
      title: 'Endereço',
      status: 20
    }
  },
  'Dados Profissionais': {
    path: ':profession/dados-profissionais',
    name: 'signup.professionaldata',
    id: 3,
    active: false,
    finished: false,
    inProgress: false,
    meta: {
      title: 'Dados Profissionais',
      status: 30
    }
  },
  'Experiência': {
    path: ':profession/experiencia',
    name: 'signup.experience',
    id: 4,
    active: false,
    finished: false,
    inProgress: false,
    meta: {
      title: 'Experiência',
      status: 40
    }
  },
  'Disponibilidade': {
    path: ':profession/disponibilidade',
    name: 'signup.availability',
    id: 5,
    active: false,
    finished: false,
    inProgress: false,
    meta: {
      title: 'Disponibilidade',
      status: 50
    }
  }
};

export interface IStep {
  path: string;
  name: string;
  id: number;
  finished: boolean;
  inProgress: boolean;
  meta: IMetaStep
}

export interface IMetaStep{
  title: string;
  status: number
}