import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layouts/Default.vue';
import PersonalData from '@/views/PersonalData.vue';
import BasicData from '@/views/BasicData.vue';
import ThankYou from '@/views/ThankYou.vue';
import Address from '@/views/Address.vue';
import ProfessionalData from '@/views/ProfessionalData.vue';
import Availability from '@/views/Availability.vue';
import Experience from '@/views/Experience.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/cadastro/psicologos',
  },
  {
    path: '/cadastro',
    component: DefaultLayout,
    redirect: '/cadastro/psicologos',
    children: [
      {
        path: ':profession',
        name: 'signup.index',
        meta: {
          title: 'Cadastro',
        },
        component: BasicData
      },
      {
        path: ':profession/sucesso',
        name: 'signup.thanks',
        meta: {
          title: 'Obrigado!',
        },
        component: ThankYou
      },
      {
        path: ':profession/sucesso/finalizacao',
        name: 'signup.thanks.finish',
        meta: {
          title: 'Obrigado!',
        },
        component: ThankYou
      },
      {
        path: ':profession/dados-pessoais',
        name: 'signup.personaldata',
        meta: {
          title: 'Dados Pessoais',
          step: 1,
        },
        component: PersonalData
      },
      {
        path: ':profession/endereco',
        name: 'signup.address',
        meta: {
          title: 'Endereço',
          step: 2,
        },
        component: Address
      },
      {
        path: ':profession/dados-profissionais',
        name: 'signup.professionaldata',
        meta: {
          title: 'Dados Profissionais',
          step: 3,
        },
        component: ProfessionalData
      },
      {
        path: ':profession/experiencia',
        name: 'signup.experience',
        meta: {
          title: 'Experiência',
          step: 4,
        },
        component: Experience
      },
      {
        path: ':profession/disponibilidade',
        name: 'signup.availability',
        meta: {
          title: 'Disponibilidade',
          step: 5,
        },
        component: Availability
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/cadastro/psicologos'
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
