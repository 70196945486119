import { createStore } from 'vuex';
import http from '@/utils/http';
import Helpers from '@/utils/helpers';
import { transformToPersonalData, AddressPayload, STEPS, IStep, PROFESSION_QUESTIONS } from '@/constants';
import { IPayloadAnswers, IPayloadUserAnswers, IRequestPostAnswers } from '@/interfaces/answers';
import { IOption } from '@/interfaces/field';
import { IRequestProfessionalData } from '@/constants/contracts/professionalData';


export default createStore({
  state: {
    step: 1,
    steps: STEPS,
    stepVisible: true,
    user: {
      initialData: {},
      personalData: {},
      professionalData: {},
      address: {},
      experience: [] as any[],
      availability: [] as any[],
      statusCode: null
    },
    token: '',
    schema: {},
    institutionsList: [],
    courseList: [],
    graduateList: [],
    graduationList: [],
    specialtys: [],
    personalDataAnswers: {
      QUE_SISTEMA_OPERACIONAL_DE_SMARTPHONE_VOCE_USA: [],
      COMO_VOCE_DESCOBRIU_A_ORIENTEME: [],
      MINHA_CONEXAO_COM_A_INTERNET_E: []
    },
    experienceAnswers: {
      QUAL_E_A_SUA_ABORDAGEM_TERAPEUTICA_INDIQUE_TODAS_AS_APLICAVEIS: [],
      QUANTOS_ANOS_DE_EXPERIENCIA_COM_ATENDIMENTO_CLINICO_VOCE_TEM: [],
      JA_ATENDEU_ONLINE_SE_SIM_POR_QUE_MEIOS: [],
      SELECIONE_ATE_10_TEMAS_PRINCIPAIS_EM_QUE_VOCE_POSSUI_EXPERIENCIA: [],
      EM_MEDIA_QUANTOS_PACIENTES_POR_SEMANA_VOCE_ATENDE: [],
      INDIQUE_A_FAIXA_ETARIA_MEDIA_DOS_SEUS_PACIENTES_ATUAIS: [],
      VOCE_TEM_UM_SUPERVISOR_CLINICO: [],
      VOCE_DA_SUPERVISAO_CLINICA: [],
      VOCE_EXERCE_ALGUMA_FUNCAO_COMO_TERAPEUTA: []
    } as any,
    availabilityAnswers: {
      QUANTO_TEMPO_VOCE_PODERA_DISPOR_PARA_TRABALHAR_COM_A_PLATAFORMA_DIARIAMENTE: [],
      COM_QUE_FREQUENCIA_VOCE_CHECA_SEU_E_MAIL: [],
      VOCE_PRETENDE_ATENDER_EM_QUAIS_IDIOMAS: [],
      QUAL_IS__FAIXA_S__ETARIA_S__VOCE_TEM_INTERESSE_EM_ATENDER_ONLINE: []
    } as any
  } as any,

  getters: {
    currentStep(state): IStep {
      return state.step;
    },
    institutionsSelectOptions(state): any[] {
      return transformIntoSelect(state.institutionsList);
    },
    courseListSelectOptions(state): any[] {
      return transformIntoSelect(state.courseList);
    },
    graduateListSelectOptions(state): any[] {
      return transformIntoSelect(state.graduateList);
    },
    specialtysSelectOptions(state): any[] {
      return transformIntoSelect(state.specialtys);
    },
    graduationListSelectOptions(state): any[] {
      return transformIntoSelect(state.graduationList);
    },
    answersListSelectOptions: (state) => (key: string) => {
      const answers = state.experienceAnswers[key];
      return transformIntoSelect(answers, 'id', 'resposta');
    },
    answers: (state) => (storeGetterKey: string, objectKey: string) => {
      if (typeof storeGetterKey !== 'string') throw new Error('Invalid type of parameters');
      if (typeof objectKey !== 'string') throw new Error('Invalid type of parameters');

      const answers = state[storeGetterKey][objectKey];
      return transformIntoSelect(answers, 'id', 'resposta');
    },
    answersByValue: (state) => (storeObject: string, storeGetterKey: string, value: string): string => {
      if (typeof value !== 'string') throw new Error('Invalid type of parameters');

      const answers = transformIntoSelect(state[storeObject][storeGetterKey], 'id', 'resposta');
      return answers.filter((x: any) => x.value === value)[0].key;
    },
    answersByKey: (state) => (storeObject: string, storeGetterKey: string, itemId: any[]): string[] => {
      if (typeof itemId === 'string') throw new Error('Invalid type of parameters');
      let answers = state[storeObject][storeGetterKey];
      answers = transformIntoSelect(answers, 'id', 'resposta');
      return itemId.map((key: any) => {
        return answers.filter((x: any) => x.key === key)[0].value;
      });
    },
    isDebug(): boolean {
      return process.env.VUE_APP_ENV === 'development';
    },
    isNewUser(state): boolean {
      return state.user.status <= 10;
    },
    cleanAddress: (state): any => {
      const DUMMY_DATA = 'dummy data';
      const address = state.user.address;
      Object.entries(address).forEach(([key, value]: any) => {
      if (typeof (key) !== 'string') return;
        if (value == DUMMY_DATA) {
          address[key] = '';
        }
        if (key === 'zipCode' && value === 0) {
          address[key] = '';
        }
        if (key === 'state' && value === 'na') {
          address[key] = '';
        }
      });
      return address;
    },
    registerSteps: (state): any => {
      for(const step of Object.values(state.steps) as IStep[]){
        if(state.user.status > step.meta.status){
          step.finished = true;
        } else if(state.user.status === step.meta.status){
          step.inProgress = true;
        }
      }
      return state.steps;
    },
    stepVisible: (state): boolean => {
      return state.stepVisible;
    }
  },

  actions: {

    // POST 
    async postBasicData(context, payload): Promise<void> {
      try {
        const data = JSON.parse(JSON.stringify(payload));
        data.cpfcnpj = Helpers.onlyNumbers(data.cpfcnpj);
        data.phoneNumber = Helpers.onlyNumbers(data.phoneNumber);

        await http.post('/createinitialdata', data);
        context.commit('SET_USER', data);
      }
      catch (err: any) {
        const error = err.erros && err.erros.length > 0 ? err.erros.join('. ') : err;
        throw new Error(error);
      }
    },
    async postPersonalData({ commit }, payload): Promise<void> {
      payload.onlineServices = payload.onlineService;
      const data = transformToPersonalData(payload);
      const response = await http.post('/personaldata', data);
      if (handleHttpResponse(response)) {
        commit('SET_PERSONAL_DATA', data);
      }
    },
    async postAddress({ commit }, payload): Promise<void> {
      try {
        const data = JSON.parse(JSON.stringify(payload)) as AddressPayload;
        data.zipCode = Helpers.onlyNumbers(data.zipCode);
        const response = await http.post(`/address/${data.uuid}`, data);
        if (response) {
          commit('SET_ADDRESS', data);
        }
      }
      catch (err: any) {
        const error = err.erros && err.erros.length > 0 ? err.erros.join('. ') : err;
        throw new Error(error);
      }
    },
    async postAvailability({ commit }, payload: IRequestPostAnswers): Promise<void> {
      const data = JSON.parse(JSON.stringify(payload)) as IRequestPostAnswers;
      const response = await http.post(`/availability/${data.uuid}`, data);
      if (handleHttpResponse(response)) {
        commit('SET_POSTED_ANSWERS', data);
      }
    },
    async postExperience({ commit }, payload: IRequestPostAnswers): Promise<void> {
      const data = JSON.parse(JSON.stringify(payload)) as IRequestPostAnswers;
      const response = await http.post(`/experience/${data.uuid}`, data);
      if (handleHttpResponse(response)) {
        commit('SET_POSTED_ANSWERS', data);
      }
    },
    async postProfessionalData({ commit }, payload: IRequestProfessionalData): Promise<void> {
      const response = await http.post('/professionaldata', payload);
      if (handleHttpResponse(response)) {
        commit('SET_PROFESSIONAL_DATA', response.data);
      }
    },
    // PICTURES
    async sendProfilePicture(context, content): Promise<void> {
      await handleUploadImageApiPicture(`/profilePicture/${content.uuid}`, content.picture);
    },
    async sendIdentificationDocumentPicture(context, content): Promise<void> {
      await handleUploadImageApiPicture(`/identificationdocument/${content.uuid}`, content.picture);
    },
    async sendGraduationPicture(context, content): Promise<void> {
      await handleUploadImageApiPicture(`/graduationpictureforregister/${content.uuid}`, content.picture);
    },
    async sendCouncilDocument(context, content): Promise<void> {
      await handleUploadImageApiPicture(`/councilndocument/${content.uuid}`, content.picture);
    },

    // FETCH
    async fetchPersonalData({ dispatch, commit }, uuid): Promise<void> {
      const INITIAL_DATA = 0;

      // fetch API data
      const promiseInitialData = http.get(`/initialdata/${uuid}`);
      const promisePersonalInfo = http.get(`/personaldata/${uuid}`);
      const promises = await Promise.all([promiseInitialData, promisePersonalInfo]);

      // commit inital data into state
      const responseInitialData = promises[INITIAL_DATA] as any;
      commit('SET_INITIAL_DATA', responseInitialData.data);

      await dispatch('hasPersonalData', promises);
      
    },
    async fetchGlobalAnswers({dispatch}, {arrayAnswers, professionType, uuid, storeQuestions, storeUserAnswers}): Promise<void>{
      const promises=[];
      for(const key in arrayAnswers) {
        const questionType=PROFESSION_QUESTIONS[key];
        if(questionType==null) {
          throw Error(`question type invalid for key: ${key}`);
        }
        promises.push(dispatch('fetchAnswers', {
          professionType,
          answerKey: key,
          questionType: PROFESSION_QUESTIONS[key],
          storeObject: storeQuestions
        } as IPayloadAnswers));
        promises.push(dispatch('fetchUserAnswers',{
          uuid: uuid,
          professionType,
          answerKey: key,
          questionType: PROFESSION_QUESTIONS[key],
          storeObject: storeUserAnswers
        } as IPayloadUserAnswers));
      }
      await Promise.all(promises);
    },
    async fetchAddress({ commit }, uuid): Promise<void> {
      const response = await http.get(`/address/${uuid}`);
      if (handleHttpResponse(response)) {
        commit('SET_ADDRESS', response.data);
      }
    },
    async fetchInstituitions({ commit }, professionType: number): Promise<void> {
      const response = await http.get(`/institutionlist/${professionType}`);
      if (handleHttpResponse(response)) {
        commit('SET_INSTITUITIONS', response.data);
      }
    },
    async fetchCourseList({ commit }, professionType: number): Promise<void> {
      const response = await http.get(`/courselist/${professionType}`);
      if (handleHttpResponse(response)) {
        commit('SET_COURSE_LIST', response.data);
      }
    },
    async fetchGraduateList({ commit }, professionType: number): Promise<void> {
      const response = await http.get(`/graduatelist/${professionType}`);
      if (handleHttpResponse(response)) {
        commit('SET_GRADUATE_LIST', response.data);
      }
    },
    async fetchGraduationList({ commit }, professionType: number): Promise<void> {
      const response = await http.get(`/graduationlist/${professionType}`);
      if (handleHttpResponse(response)) {
        commit('SET_GRADUATION_LIST', response.data);
      }
    },
    async fetchSpecialtyList({ commit }, professionType: number): Promise<void> {
      const response = await http.get(`/specialtylist/${professionType}`);
      if (handleHttpResponse(response)) {
        commit('SET_SPECIALTY_LIST', response.data);
      }
    },
    async fetchAnswers({ commit }, { professionType, questionType, answerKey, storeObject }: IPayloadAnswers): Promise<void> {
      const LANGUAGE_PT_BR = 1;
      const response = await http.get(`/answers/${professionType}/${questionType}/${LANGUAGE_PT_BR}`);
      if (handleHttpResponse(response)) {
        commit('SET_OPTIONS_ANSWERS', {
          storeObject: storeObject,
          key: answerKey,
          answers: response.data
        });
      }
    },
    async fetchUserAnswers({ commit }, { uuid, professionType, questionType, storeObject }: IPayloadUserAnswers): Promise<void> {
      const LANGUAGE_PT_BR = 1;
      const response = await http.get(`/answers/${uuid}/${professionType}/${questionType}/${LANGUAGE_PT_BR}`);
      if (handleHttpResponse(response)) {
        commit('SET_USER_ANSWERS', {
          storeObject,
          payload: {
            questionType,
            answersId: response.data
          }
        });
      }
    },
    async fetchProfessionalData({ commit }, uuid): Promise<void> {
      const response = await http.get(`/professionaldata/${uuid}`);
      if (handleHttpResponse(response)) {
        commit('SET_PROFESSIONAL_DATA', response.data);
      }
    },
    async fetchRegisterStatus({ commit }, uuid): Promise<void> {
      const response = await http.get(`/status/${uuid}`) as any;
      if (handleHttpResponse(response)) {
        commit('SET_USER_STATUS', response.data.status);
      }
    },
    async fetch({ commit }, uuid): Promise<void> {
      const response = await http.get(`/status/${uuid}`) as any;
      if (handleHttpResponse(response)) {
        commit('SET_USER_STATUS', response.data.status);
      }
    },
    // OTHERS
    goToStep({ commit }, payload): void {
      commit('SET_STEP', payload);
    },
    async hasPersonalData({ commit }, payload): Promise<void> {
      const PERSONAL_INFORMATION = 1;
      //check is personal information already finished
      const responsePersonalInfo = payload[PERSONAL_INFORMATION] as any;
      const hasFinishedPersonalData =
        responsePersonalInfo.data.dateOfBirth != null
        && responsePersonalInfo.data.whyDoYouWantToMeetOnThePlatform != null
        && responsePersonalInfo.data.cpfCnpj != null
        && !this.getters.isNewUser;

      //advances user into address part
      if (hasFinishedPersonalData) {
        commit('SET_PERSONAL_DATA', responsePersonalInfo.data);
      }
    },
    async isFormFinished({ dispatch, state }: any, uuid: string): Promise<boolean> {
      if(!uuid) return false;
      try {
        await Promise.all([
          await dispatch('fetchPersonalData', uuid),
          await dispatch('fetchAddress', uuid),
          await dispatch('fetchProfessionalData', uuid),
          await dispatch('fetchRegisterStatus', uuid),
        ]);
        const user = state.user;
        return user.address != null &&
          user.address.city != null &&
          user.availability != null &&
          user.availability.length > 0 &&
          user.personalData != null &&
          user.personalData.name != null &&
          user.experience != null &&
          user.experience.length > 0 &&
          user.professionalData != null &&
          user.professionalData.institutions != null &&
          user.professionalData.institutions.length > 0;
      }
      catch (error) {
        return false;
      }
    },
    async restore({ dispatch, commit, state }: any, { uuid }: any): Promise<void> {
      if(!uuid) return;
      await dispatch('fetchRegisterStatus', uuid);
      const {user} = state;
      let actualStep = null;
      for(const s of Object.values(STEPS)){
        const step = s as any;
        if(user.status === step.meta.status){
          actualStep = step;
          break;
        }
      }
      console.log('actual step: '+ actualStep);
      if (actualStep) {
        commit('SET_STEP', actualStep);
      }
    },
    advanceStep({commit}:any,  step: IStep): void {
      commit('SET_USER_STATUS', step.meta.status);
      commit('SET_STEP', step);
    },
    setStepsVisible({commit}: any, status: boolean): void {
      commit('SET_STEP_VISIBILITY', status);
    },
    refreshStateApp({commit}: any): void{
      commit('REFRESH_STATE_APP');
    }
  },

  mutations: {
    SET_USER(state, user) {
      state.user = { ...state.user, ...user };
    },
    SET_PERSONAL_DATA(state, data) {
      state.user.personalData = data;
    },
    SET_ADDRESS(state, data) {
      state.user.address = data;
    },
    SET_STEP(state, step: IStep) {
      state.step = step;
    },
    SET_INITIAL_DATA(state, initialData) {
      state.user.initialData = { ...state.user, ...initialData };
    },
    SET_INSTITUITIONS(state, institutions) {
      state.institutionsList = institutions;
    },
    SET_COURSE_LIST(state, courseList) {
      state.courseList = courseList;
    },
    SET_GRADUATE_LIST(state, list) {
      state.graduateList = list;
    },
    SET_SPECIALTY_LIST(state, list) {
      state.specialtys = list;
    },
    SET_GRADUATION_LIST(state, list) {
      state.graduationList = list;
    },
    SET_OPTIONS_ANSWERS(state, { storeObject, key, answers }) {
      state[storeObject][key] = answers;
    },
    SET_POSTED_ANSWERS(state, payload) {
      state.user.experience.push(payload);
    },
    SET_USER_ANSWERS(state, { storeObject, payload }) {
      if(Array.isArray(state.user[storeObject])){
        state.user[storeObject].push(payload);
      }else{
        if(!state.user[storeObject]['userAnswers']){
          state.user[storeObject]['userAnswers'] = [];
        }
        state.user[storeObject]['userAnswers'].push(payload);
      }
    },
    SET_PROFESSIONAL_DATA(state, data) {
      state.user.professionalData = data;
    },
    SET_USER_STATUS(state, status) {
      state.user.status = status;
    },
    SET_STEP_VISIBILITY(state, status){
      state.stepVisible = status;
    },
    REFRESH_STATE_APP(state){
      state.personalDataAnswers = {
        SELECIONE_ATE_10_TEMAS_PRINCIPAIS_EM_QUE_VOCE_POSSUI_EXPERIENCIA: [],
        QUE_SISTEMA_OPERACIONAL_DE_SMARTPHONE_VOCE_USA: [],
        COMO_VOCE_DESCOBRIU_A_ORIENTEME: [],
        MINHA_CONEXAO_COM_A_INTERNET_E: []
      };
      state.experienceAnswers = {
        QUAL_E_A_SUA_ABORDAGEM_TERAPEUTICA_INDIQUE_TODAS_AS_APLICAVEIS: [],
        QUANTOS_ANOS_DE_EXPERIENCIA_COM_ATENDIMENTO_CLINICO_VOCE_TEM: [],
        JA_ATENDEU_ONLINE_SE_SIM_POR_QUE_MEIOS: [],
        SELECIONE_ATE_10_TEMAS_PRINCIPAIS_EM_QUE_VOCE_POSSUI_EXPERIENCIA: [],
        EM_MEDIA_QUANTOS_PACIENTES_POR_SEMANA_VOCE_ATENDE: [],
        INDIQUE_A_FAIXA_ETARIA_MEDIA_DOS_SEUS_PACIENTES_ATUAIS: [],
        VOCE_TEM_UM_SUPERVISOR_CLINICO: [],
        VOCE_DA_SUPERVISAO_CLINICA: [],
        VOCE_EXERCE_ALGUMA_FUNCAO_COMO_TERAPEUTA: []
      };
      state.availabilityAnswers = {
        QUANTO_TEMPO_VOCE_PODERA_DISPOR_PARA_TRABALHAR_COM_A_PLATAFORMA_DIARIAMENTE: [],
        COM_QUE_FREQUENCIA_VOCE_CHECA_SEU_E_MAIL: [],
        VOCE_PRETENDE_ATENDER_EM_QUAIS_IDIOMAS: [],
        QUAL_IS__FAIXA_S__ETARIA_S__VOCE_TEM_INTERESSE_EM_ATENDER_ONLINE: []
      };
      state.user.experience = [];
      state.user.availability = [];
    }
  },
  modules: {}
});

const transformIntoSelect = function (
  result = [],
  keyId = 'id',
  keyValue = 'nome'): IOption[] {
  return result.map((x: any) => {
    return {
      key: x[keyId],
      value: x[keyValue]
    } as IOption;
  });
};

const handleHttpResponse = (httpResponse: any): boolean => {
  if (httpResponse.isSuccess) { return true; }
  return false;
};

const handleUploadImageApiPicture = (url: string, picture: any): Promise<any> | undefined => {
  if (!picture) return;
  const formData = new FormData();
  if(Array.isArray(picture)){
    for(let i = 0; i < picture.length; i++){
      formData.append('file', picture[i].raw);
    }
  }else{
    formData.append('file', picture.raw);
  }
  return http.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};